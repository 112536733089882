//import tags
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import 'swiper/css';
import 'swiper/css/navigation';

// import recources
import tbilisiImage from '../../assets/image/city.jpg'


function City() {

    return (
        <Container>
            <Row>
                <Col xxl={12}>
                    <section className="citySection">
                        
                        <div className="sectionHeader">
                            <div className="sectionHeaderLeft">
                                <h2>პოპულარული ქალაქები</h2>
                                <p>მოიძიეთ სასურველი განცხადება ქალაქების მიხედვით.</p>
                            </div>
                            <div className="sectionHeaderRight">
                                <Link to="/cities">ყველას ნახვა <FontAwesomeIcon icon={faChevronRight} /></Link>
                            </div>
                        </div>
                        
                        <div className="citySlider">
                            <Swiper
                                // install Swiper modules
                                modules={[Navigation, A11y]}
                                spaceBetween={20}
                                slidesPerView={5}
                                navigation
                            >
                                <SwiperSlide>
                                    <Link to="/">
                                        <div className="singleCityBox">
                                            <div className="singleCityBoxImage">
                                                <Image src={tbilisiImage} alt="City image" />
                                            </div>
                                            <div className="cityOverlay">
                                                <div className="overCity">
                                                    <h3>თბილისი</h3>
                                                    <p>244 განცხადება</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to="/">
                                        <div className="singleCityBox">
                                            <div className="singleCityBoxImage">
                                                <Image src={tbilisiImage} alt="City image" />
                                            </div>
                                            <div className="cityOverlay">
                                                <div className="overCity">
                                                    <h3>თბილისი</h3>
                                                    <p>244 განცხადება</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to="/">
                                        <div className="singleCityBox">
                                            <div className="singleCityBoxImage">
                                                <Image src={tbilisiImage} alt="City image" />
                                            </div>
                                            <div className="cityOverlay">
                                                <div className="overCity">
                                                    <h3>თბილისი</h3>
                                                    <p>244 განცხადება</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to="/">
                                        <div className="singleCityBox">
                                            <div className="singleCityBoxImage">
                                                <Image src={tbilisiImage} alt="City image" />
                                            </div>
                                            <div className="cityOverlay">
                                                <div className="overCity">
                                                    <h3>თბილისი</h3>
                                                    <p>244 განცხადება</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to="/">
                                        <div className="singleCityBox">
                                            <div className="singleCityBoxImage">
                                                <Image src={tbilisiImage} alt="City image" />
                                            </div>
                                            <div className="cityOverlay">
                                                <div className="overCity">
                                                    <h3>თბილისი</h3>
                                                    <p>244 განცხადება</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to="/">
                                        <div className="singleCityBox">
                                            <div className="singleCityBoxImage">
                                                <Image src={tbilisiImage} alt="City image" />
                                            </div>
                                            <div className="cityOverlay">
                                                <div className="overCity">
                                                    <h3>თბილისი</h3>
                                                    <p>244 განცხადება</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                    </section>
                </Col>
            </Row>
        </Container>
    )

}

export default City;