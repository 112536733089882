//import tags
import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from './../../components/Context';

import Select from 'react-select';
import DatePicker from "react-datepicker";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y } from 'swiper/modules';
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Container, Row, Col, Image } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Skeleton from 'react-loading-skeleton'

//import resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faList, faPhotoFilm, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import "react-datepicker/dist/react-datepicker.css";

import 'swiper/css';
import 'swiper/css/navigation';

import Propertyimage from '../../assets/image/property.jpg'

//api
import { insert_property } from '../../api/Api'

const propertyTypeOptions = [
    { value: 'apartment', label: 'ბინა, აპარტამენტი' },
    { value: 'penthouse', label: 'პენტჰაუსი' },
    { value: 'house', label: 'კერძო სახლი - აგარაკი' },
    { value: 'townhouse', label: 'თაუნ ჰაუსი' },
    { value: 'comercial', label: 'კომერციული ფართი' },
    { value: 'office', label: 'საოფისე ფართი' },
    { value: 'hotel', label: 'სასტუმრო' },
    { value: 'businesscenter', label: 'ბიზნეს ცენტრი' },
    { value: 'land', label: 'მიწა' },
    { value: 'business', label: 'ბიზნესი - ინვესტიცია' },
    { value: 'farm', label: 'ფერმა' },
];

const transactionTypeOptions = [
    { value: 'sale', label: 'იყიდება' },
    { value: 'installment', label: 'იყიდება განვადებით' },
    { value: 'rent', label: 'ქირავდება' },
    { value: 'dailyrent', label: 'ქირავდება დღიურად' },
    { value: 'pawn', label: 'გირავდება' },
    { value: 'pawnwithowner', label: 'გირავდება ცხოვრების უფლებით' },
    { value: 'auction', label: 'იყიდება აუციონით' },
    { value: 'change', label: 'იცვლება' },
];


const propertyStatusOptions = [
    { value: 'new', label: 'ახალი აშენებული' },
    { value: 'underconstruction', label: 'მშენებარე' },
    { value: 'old', label: 'ძველი აშენებული' },
    { value: 'hystorical', label: 'ისტრიული შენობა' },
];

const propertyProjectOptions = [
    { value: 'nonstandard', label: 'არასტანდარტული' },
    { value: 'czechia', label: 'ჩეხური' },
    { value: 'urban', label: 'ქალაქური' },
    { value: 'khrushchev', label: 'ხრუშოვი' },
    { value: 'kavlashvili', label: 'ყავლაშვილი' },
    { value: 'tukharuli', label: 'თუხარული' },
    { value: 'moscow', label: 'მოსკოვი' },
    { value: 'lvov', label: 'ლვოვი' },
    { value: 'tbilisi', label: 'თბილისური ეზო' },
    { value: 'old', label: 'ძველი რემონტი' },
    { value: 'repair', label: 'სარემონტო' },
];

const propertyProjectClassOptions = [
    { value: 'premiumbusiness', label: 'პრემიუმ ბიზნეს კლასი' },
    { value: 'premium', label: 'პრემიუმ კლასი' },
    { value: 'medium', label: 'მედიუმ კლასი' },
    { value: 'econom', label: 'ეკონომ კლასი' },
];

const propertyConditionOptions = [
    { value: 'newly', label: 'ახალი გარემონტებული' },
    { value: 'modern', label: 'თანამედროვე რემონტი (Lux)' },
    { value: 'ongoing', label: 'მიმდინარე რემონტი' },
    { value: 'white', label: 'თეთრი რემონტი' },
    { value: 'greenframe', label: 'მწვანე კარკასი' },
    { value: 'whiteframe', label: 'თეთრი კარკასი' },
    { value: 'greyframe', label: 'რუხი კარკასი' },
    { value: 'blackframe', label: 'შავი კარკასი' },
    { value: 'underconstruction', label: 'მშენებარე' },
];

const cityOptions = [
    { value: 'tbilisi', label: 'თბილისი' },
    { value: 'batumi', label: 'ბათუმი' },
    { value: 'kutaisi', label: 'ქუთაისი' },
    { value: 'rustavi', label: 'რუსთავი' },
    { value: 'abastumani', label: 'აბასთუმანი' },
    { value: 'abasha', label: 'აბაშა' },
    { value: 'agara', label: 'აგარა' },
    { value: 'adigeni', label: 'ადიგენი' },
    { value: 'algeti', label: 'ალგეთი' },
    { value: 'ambrolauri', label: 'ამბროლაური' },
    { value: 'anaklia', label: 'ანაკლია' },
    { value: 'ananuri', label: 'ანანური' },
    { value: 'aspindza', label: 'ასპინძა' },
    { value: 'akhaldaba', label: 'ახალდაბა' },
    { value: 'akhalgori', label: 'ახალგორი' },
    { value: 'akhalkalaki', label: 'ახალქალაქი' },
    { value: 'akhaltsikhe', label: 'ახალციხე' },
    { value: 'akhmeta', label: 'ახმეტა' },
    { value: 'bakuriani', label: 'ბაკურიანი' },
    { value: 'bandza', label: 'ბანძა' },
    { value: 'baghdati', label: 'ბაღდათი' },
    { value: 'bakhmaro', label: 'ბახმარო' },
    { value: 'boboqvati', label: 'ბობოყვათი' },
    { value: 'bodbe', label: 'ბოდბე' },
    { value: 'bolnisi', label: 'ბოლნისი' },
    { value: 'borjomi', label: 'ბორჯომი' },
    { value: 'bulachauri', label: 'ბულაჩაური' },
    { value: 'gamarjveba', label: 'გამარჯვება' },
    { value: 'gardabani', label: 'გარდაბანი' },
    { value: 'geguti', label: 'გეგუთი' },
    { value: 'gombori', label: 'გომბორი' },
    { value: 'gonio', label: 'გონიო' },
    { value: 'gori', label: 'გორი' },
    { value: 'gremi', label: 'გრემი' },
    { value: 'grigoleti', label: 'გრიგოლეთი' },
    { value: 'gudauri', label: 'გუდაური' },
    { value: 'gulgula', label: 'გულგულა' },
    { value: 'gurjaani', label: 'გურჯაანი' },
    { value: 'danispiruli', label: 'დანისპირული' },
    { value: 'dedoplistsqaro', label: 'დედოფლისწყარო' },
    { value: 'dilikauri', label: 'დილიკაური' },
    { value: 'dmanisi', label: 'დმანისი' },
    { value: 'doesi', label: 'დოესი' },
    { value: 'dusheti', label: 'დუშეთი' },
    { value: 'eniseli', label: 'ენისელი' },
    { value: 'vazisubani', label: 'ვაზისუბანი' },
    { value: 'vani', label: 'ვანი' },
    { value: 'vartsikhe', label: 'ვარციხე' },
    { value: 'velistsikhe', label: 'ველისციხე' },
    { value: 'zana', label: 'ზანა' },
    { value: 'zedazeni', label: 'ზედაზენი' },
    { value: 'zestaponi', label: 'ზესტაფონი' },
    { value: 'zugdidi', label: 'ზუგდიდი' },
    { value: 'tetritsqlebi', label: 'თეთრი წყლები' },
    { value: 'tetritsqaro', label: 'თეთრიწყარო' },
    { value: 'telavi', label: 'თელავი' },
    { value: 'terjola', label: 'თერჯოლა' },
    { value: 'tianeti', label: 'თიანეთი' },
    { value: 'igoeti', label: 'იგოეთი' },
    { value: 'iqalto', label: 'იყალთო' },
    { value: 'kavtiskhevi', label: 'კავთისხევი' },
    { value: 'kazreti', label: 'კაზრეთი' },
    { value: 'kardenakhi', label: 'კარდენახი' },
    { value: 'kaspi', label: 'კასპი' },
    { value: 'kechkhobi', label: 'კეჩხობი' },
    { value: 'kvariati', label: 'კვარიათი' },
    { value: 'lagodekhi', label: 'ლაგოდეხი' },
    { value: 'lanchkhuti', label: 'ლანჩხუთი' },
    { value: 'likani', label: 'ლიკანი' },
    { value: 'manavi', label: 'მანავი' },
    { value: 'manglisi', label: 'მანგლისი' },
    { value: 'marneuli', label: 'მარნეული' },
    { value: 'martvili', label: 'მარტვილი' },
    { value: 'martqopi', label: 'მარტყოფი' },
    { value: 'matani', label: 'მატანი' },
    { value: 'makhinjauri', label: 'მახინჯაური' },
    { value: 'mestia', label: 'მესტია' },
    { value: 'metekhi', label: 'მეტეხი' },
    { value: 'misaktsieli', label: 'მისაქციელი' },
    { value: 'mukuzani', label: 'მუკუზანი' },
    { value: 'mukhrani', label: 'მუხრანი' },
    { value: 'mtskheta', label: 'მცხეთა' },
    { value: 'mtsvanekontskhi', label: 'მწვანე კონცხი' },
    { value: 'natanebi', label: 'ნატანები' },
    { value: 'natakhtari', label: 'ნატახტარი' },
    { value: 'ninotsminda', label: 'ნინოწმინდა' },
    { value: 'norio', label: 'ნორიო' },
    { value: 'noste', label: 'ნოსტე' },
    { value: 'nunisi', label: 'ნუნისი' },
    { value: 'odishi', label: 'ოდიში' },
    { value: 'ozurgeti', label: 'ოზურგეთი' },
    { value: 'okami', label: 'ოკამი' },
    { value: 'oni', label: 'ონი' },
    { value: 'pankisi', label: 'პანკისი' },
    { value: 'pirvelimaisi', label: 'პირველი მაისი' },
    { value: 'zhinvali', label: 'ჟინვალი' },
    { value: 'rukhi', label: 'რუხი' },
    { value: 'sagarejo', label: 'საგარეჯო' },
    { value: 'sadakhlo', label: 'სადახლო' },
    { value: 'sazano', label: 'საზანო' },
    { value: 'sairme', label: 'საირმე' },
    { value: 'samtavisi', label: 'სამთავისი' },
    { value: 'samtredia', label: 'სამტრედია' },
    { value: 'sartichala', label: 'სართიჭალა' },
    { value: 'sarpi', label: 'სარფი' },
    { value: 'sachkhere', label: 'საჩხერე' },
    { value: 'senaki', label: 'სენაკი' },
    { value: 'sviri', label: 'სვირი' },
    { value: 'simoneti', label: 'სიმონეთი' },
    { value: 'sioni', label: 'სიონი' },
    { value: 'sighnaghi', label: 'სიღნაღი' },
    { value: 'surami', label: 'სურამი' },
    { value: 'supsa', label: 'სუფსა' },
    { value: 'tibaani', label: 'ტიბაანი' },
    { value: 'tqibuli', label: 'ტყიბული' },
    { value: 'ureki', label: 'ურეკი' },
    { value: 'utsera', label: 'უწერა' },
    { value: 'ujarma', label: 'უჯარმა' },
    { value: 'pasanauri', label: 'ფასანაური' },
    { value: 'poti', label: 'ფოთი' },
    { value: 'pshaveli', label: 'ფშაველი' },
    { value: 'kareli', label: 'ქარელი' },
    { value: 'keda', label: 'ქედა' },
    { value: 'kvishkheti', label: 'ქვიშხეთი' },
    { value: 'kvemoaranisi', label: 'ქვემო არანისი' },
    { value: 'kobuleti', label: 'ქობულეთი' },
    { value: 'ksani', label: 'ქსანი' },
    { value: 'kubriantkari', label: 'ქუბრიანთკარი' },
    { value: 'kveseti', label: 'კვესეთი' },
    { value: 'qazbegi', label: 'ყაზბეგი' },
    { value: 'qvareli', label: 'ყვარელი' },
    { value: 'qulevi', label: 'ყულევი' },
    { value: 'shatili', label: 'შატილი' },
    { value: 'shekvetili', label: 'შეკვეთილი' },
    { value: 'shilda', label: 'შილდა' },
    { value: 'shindisi', label: 'შინდისი' },
    { value: 'shiomghvime', label: 'შიომღვიმე' },
    { value: 'shovi', label: 'შოვი' },
    { value: 'shorapani', label: 'შორაპანი' },
    { value: 'shuamta', label: 'შუამთა' },
    { value: 'shuakhevi', label: 'შუახევი' },
    { value: 'chakvi', label: 'ჩაქვი' },
    { value: 'chokhatauri', label: 'ჩოხატაური' },
    { value: 'chkhorotsqu', label: 'ჩხოროწყუ' },
    { value: 'tsageri', label: 'ცაგერი' },
    { value: 'tsemi', label: 'ცემი' },
    { value: 'tsikhisdziri', label: 'ციხისძირი' },
    { value: 'tskhvarichamia', label: 'ცხვარიჭამია' },
    { value: 'tskhinvali', label: 'ცხინვალი' },
    { value: 'tsalenjikha', label: 'წალენჯიხა' },
    { value: 'tsalka', label: 'წალკა' },
    { value: 'tsaghveri', label: 'წაღვერი' },
    { value: 'tserovani', label: 'წეროვანი' },
    { value: 'tsilkani', label: 'წილკანი' },
    { value: 'tsinandali', label: 'წინანდალი' },
    { value: 'tsitsamuri', label: 'წიწამური' },
    { value: 'tsnori', label: 'წნორი' },
    { value: 'tsqaltubo', label: 'წყალტუბო' },
    { value: 'chiatura', label: 'ჭიათურა' },
    { value: 'choporti', label: 'ჭოპორტი' },
    { value: 'kharagauli', label: 'ხარაგაული' },
    { value: 'khashmi', label: 'ხაშმი' },
    { value: 'khashuri', label: 'ხაშური' },
    { value: 'khevsureti', label: 'ხევსურეთი' },
    { value: 'khelvachauri', label: 'ხელვაჩაური' },
    { value: 'khobi', label: 'ხობი' },
    { value: 'khoni', label: 'ხონი' },
    { value: 'khulo', label: 'ხულო' },
    { value: 'kachreti', label: 'კაჭრეთი' },
    { value: 'java', label: 'ჯავა' },
];

const districtOptions = [
    { value: 'apkhazeti', label: 'აფხაზეთი' },
    { value: 'achara', label: 'აჭარა' },
    { value: 'guria', label: 'გურია' },
    { value: 'imereti', label: 'იმერეთი' },
    { value: 'kakheti', label: 'კახეთი' },
    { value: 'mtskhetamtianeti', label: 'მცხეთა-მთიანეთი' },
    { value: 'rachalechkhumi', label: 'რაჭა-ლეჩხუმი და ქვემო სვანეთი' },
    { value: 'samegrelozemosvaneti', label: 'სამეგრელო-ზემო სვანეთი' },
    { value: 'samtskhejavakheti', label: 'სამცხე-ჯავახეთი' },
    { value: 'kvemokartli', label: 'ქვემო ქართლი' },
    { value: 'shidakartli', label: 'შიდა ქართლი' },
];

const municipalOptions = [
    { value: 'abasha', label: 'აბაშის მუნიციპალიტეტი' },
    { value: 'adigeni', label: 'ადიგენის მუნიციპალიტეტი' },
    { value: 'khulo', label: 'ხულოს მუნიციპალიტეტი' },
    { value: 'ambrolauri', label: 'ამბროლაურის მუნიციპალიტეტი' },
    { value: 'aspindza', label: 'ასპინძის მუნიციპალიტეტი' },
    { value: 'apkhazeti', label: 'აფხაზეთის ავტონომიური რესპუბლიკა' },
    { value: 'khoni', label: 'ხონის მუნიციპალიტეტი' },
    { value: 'akhalkalaki', label: 'ახალქალაქის მუნიციპალიტეტი' },
    { value: 'khobi', label: 'ხობის მუნიციპალიტეტი' },
    { value: 'khelvachauri', label: 'ხელვაჩაურის მუნიციპალიტეტი' },
    { value: 'akhaltsikhe', label: 'ახალციხის მუნიციპალიტეტი' },
    { value: 'khashuri', label: 'ხაშურის მუნიციპალიტეტი' },
    { value: 'akhmeta', label: 'ახმეტის მუნიციპალიტეტი' },
    { value: 'baghdati', label: 'ბაღდათის მუნიციპალიტეტი' },
    { value: 'bolnisi', label: 'ბოლნისის მუნიციპალიტეტი' },
    { value: 'borjomi', label: 'ბორჯომის მუნიციპალიტეტი' },
    { value: 'kharagauli', label: 'ხარაგაულის მუნიციპალიტეტი' },
    { value: 'gardabni', label: 'გარდაბნის მუნიციპალიტეტი' },
    { value: 'gori', label: 'გორის მუნიციპალიტეტი' },
    { value: 'tsqaltubo', label: 'წყალტუბოს მუნიციპალიტეტი' },
    { value: 'tsalka', label: 'წალკის მუნიციპალიტეტი' },
    { value: 'gurjaani', label: 'გურჯაანის მუნიციპალიტეტი' },
    { value: 'tsalenjikha', label: 'წალენჯიხის მუნიციპალიტეტი' },
    { value: 'dedoplistsqaro', label: 'დედოფლისწყაროს მუნიციპალიტეტი' },
    { value: 'dmanisi', label: 'დმანისის რაიონი' },
    { value: 'tskhinvali', label: 'ცხინვალის რაიონი' },
    { value: 'tsageri', label: 'ცაგერის მუნიციპალიტეტი' },
    { value: 'chkhorotsqu', label: 'ჩხოროწყუს მუნიციპალიტეტი' },
    { value: 'vani', label: 'ვანის მუნიციპალიტეტი' },
    { value: 'zestaponi', label: 'ზესტაფონის მუნიციპალიტეტი' },
    { value: 'zugdidi', label: 'ზუგდიდის მუნიციპალიტეტი' },
    { value: 'chokhatauri', label: 'ჩოხატაურის მუნიციპალიტეტი' },
    { value: 'shuakhevi', label: 'შუახევის მუნიციპალიტეტი' },
    { value: 'tetritsqaro', label: 'თეთრიწყაროს მუნიციპალიტეტი' },
    { value: 'telavi', label: 'თელავის მუნიციპალიტეტი' },
    { value: 'qvareli', label: 'ყვარლის მუნიციპალიტეტი' },
    { value: 'terjola', label: 'თერჯოლის მუნიციპალიტეტი' },
    { value: 'tianeti', label: 'თიანეთის მუნიციპალიტეტი' },
    { value: 'qazbegi', label: 'ყაზბეგის მუნიციპალიტეტი' },
    { value: 'kutaisi', label: 'ქუთაისის მუნიციპალიტეტი' },
    { value: 'kobuleti', label: 'ქობულეთის მუნიციპალიტეტი' },
    { value: 'kaspi', label: 'კასპის მუნიციპალიტეტი' },
    { value: 'lagodekhi', label: 'ლაგოდეხის მუნიციპალიტეტი' },
    { value: 'keda', label: 'ქედის მუნიციპალიტეტი' },
    { value: 'lanchkhuti', label: 'ლანჩხუთის მუნიციპალიტეტი' },
    { value: 'lentekhi', label: 'ლენტეხის მუნიციპალიტეტი' },
    { value: 'kareli', label: 'ქარელის მუნიციპალიტეტი' },
    { value: 'marneuli', label: 'მარნეულის მუნიციპალიტეტი' },
    { value: 'tqibuli', label: 'ტყიბულის მუნიციპალიტეტი' },
    { value: 'martvili', label: 'მარტვილის მუნიციპალიტეტი' },
    { value: 'sighnaghi', label: 'სიღნაღის მუნიციპალიტეტი' },
    { value: 'senaki', label: 'სენაკის რაიონი' },
    { value: 'sachkhere', label: 'საჩხერის რაიონი' },
    { value: 'mestia', label: 'მესტიის მუნიციპალიტეტი' },
    { value: 'samtredia', label: 'სამტრედიის მუნიციპალიტეტი' },
    { value: 'sagarejo', label: 'საგარეჯოს მუნიციპალიტეტი' },
    { value: 'oni', label: 'ონის რაიონი' },
    { value: 'ozurgeti', label: 'ოზურგეთის მუნიციპალიტეტი' },
    { value: 'ninotsminda', label: 'ნინოწმინდის რაიონი' },
    { value: 'mtskheta', label: 'მცხეთის მუნიციპალიტეტი' },
    { value: 'akhalgori', label: 'ახალგორის მუნიციპალიტეტი' },
];

const areaTypes = [
    { value: 'commercial', label: 'სავაჭრო' },
    { value: 'warehouse', label: 'სასაწყობე' },
    { value: 'industrialspace', label: 'საწარმოო ფართი' },
    { value: 'foodestablishment', label: 'კვების ობიექტი' },
    { value: 'special', label: 'სპეციალური' },
    { value: 'kiosk', label: 'ჯიხური' },
    { value: 'pavilion', label: 'პავილიონი' },
    { value: 'basement', label: 'სარდაფი' },
    { value: 'semibasement', label: 'ნახევარ სარდაფი' },
    { value: 'garage', label: 'ავტოფარეხი' },
    { value: 'autoservice', label: 'ავტო სერვისი' },
    { value: 'carwash', label: 'ავტო სამრეცხაო' },
    { value: 'businessyard', label: 'საქმიანი ეზო' },
    { value: 'wholebuilding', label: 'მთლიანი შენობა' },
    { value: 'newlybuiltbuilding', label: 'ახალი აშენებული კორპუსი' },
    { value: 'oldbuilding', label: 'ძველი აშენებული კორპუსი' },
    { value: 'universal', label: 'უნივერსალური' },
];

const landTypes = [
    { value: 'agricultural', label: 'სასოფლო სამეურნეო' },
    { value: 'nonagricultural', label: 'არასასოფლო სამეურნეო' },
    { value: 'commercial', label: 'კომერციული' },
    { value: 'special', label: 'სპეციალური' },
    { value: 'investmentconstruction', label: 'საინვესტიციო მშენებლობისთვის' },
    { value: 'arableland', label: 'სახნავ სათესი' },
];

const functgionZones = [
    { value: 'sz', label: 'სზ (1-6)' },
    { value: 'apz', label: 'APZ' },
    { value: 'grg', label: 'GRG' },
];

const agriculturalTypes = [
    { value: 'arable', label: 'სახნავი' },
    { value: 'hayfield', label: 'სათიბი' },
    { value: 'pasture', label: 'საძოვარი' },
    { value: 'orchard', label: 'საბაღე' },
    { value: 'vegetablegarden', label: 'საბოსტნე' },
    { value: 'homestead', label: 'საკარმიდამო' },
    { value: 'forestry', label: 'სატყეო' },
];

const hotelStars = [
    { value: '1star', label: '1 ვარსკვლავი' },
    { value: '2star', label: '2 ვარსკვლავი' },
    { value: '3star', label: '3 ვარსკვლავი' },
    { value: '4star', label: '4 ვარსკვლავი' },
    { value: '5star', label: '5 ვარსკვლავი' },
];

const eatings = [
    { value: 'singleuse', label: 'ერთჯერადი' },
    { value: 'doubleuse', label: 'ორჯერადი' },
    { value: 'tripleuse', label: 'სამჯერადი' },
];

const businesscenterClasses = [
    { value: 'aclass', label: 'A კლასი' },
    { value: 'aaclass', label: 'AA კლასი' },
    { value: 'bclass', label: 'B კლასი' },
    { value: 'cclass', label: 'C კლასი' },
    { value: 'withoutclass', label: 'კლასის გარეშე' },
];

const businessIndustryes = [
    { value: 'manufacturingfactory', label: 'წარმოება ქარხანა' },
    { value: 'foodestablishment', label: 'კვების ობიექტი' },
    { value: 'equipmentmachines', label: 'ტექნიკა დანადგარები' },
    { value: 'hotel', label: 'სასტუმრო' },
    { value: 'gasstation', label: 'ბენზინგასამართი სადგური' },
    { value: 'gasstationlicense', label: 'ბენზინგასამართი სადგურის ლიცენზია' },
    { value: 'carwash', label: 'ავტოსამრეცხაო' },
    { value: 'shop', label: 'მაღაზია' },
    { value: 'operatingbusiness', label: 'მოქმედი ბიზნესი' },
    { value: 'construction', label: 'მშენებლობა' },
    { value: 'autopartsbusiness', label: 'ავტონაწილების ბიზნესი' },
    { value: 'furniturebusiness', label: 'ავეჯის ბიზნესი' },
    { value: 'investmentproject', label: 'საინვესტიციო პროექტი' },
    { value: 'buildingmaterials', label: 'სამშენებლო მასალები' },
    { value: 'other', label: 'სხვა' },
];

const owners = [
    { value: 'individual', label: 'ფიზიკური პირი' },
    { value: 'smallbusiness', label: 'მცირე მეწარმე' },
    { value: 'legalentity', label: 'იურიდიული პირი' },
];

const ownerTypes = [
    { value: 'owner', label: 'მესაკუთრე' },
    { value: 'lease', label: 'იჯარა' },
    { value: 'legalentity', label: 'იურიდიული პირი' },
];

const actives = [
    { value: 'yes', label: 'კი' },
    { value: 'no', label: 'არა' },
];

const farmTypes = [
    { value: 'cattle', label: 'მსხვილფეხა' },
    { value: 'poultryfarming', label: 'მეფრინველეობა' },
    { value: 'dairyfarm', label: 'რძის ფერმა' },
    { value: 'greenhouse', label: 'სათბური' },
    { value: 'winemaking', label: 'მეღვინეობა' },
    { value: 'vineyard', label: 'ვენახი' },
    { value: 'fruitorchard', label: 'ხეხილი-ბაღი' },
    { value: 'vegetables', label: 'ბოსტნეული' },
    { value: 'berries', label: 'კენკროვანი' },
    { value: 'hazelnuts', label: 'თხილის' },
    { value: 'walnuts', label: 'კაკლის' },
    { value: 'fishfarm', label: 'საკალმახე' },
    { value: 'incubator', label: 'ინკუბატორი' },
    { value: 'storagefridge', label: 'შესანახი მაცივარი' },
    { value: 'breedingfarm', label: 'საჯიშე ფერმერული მეურნეობა' },
    { value: 'other', label: 'სხვა' },
];

const farmLandTypes = [
    { value: 'agricultural', label: 'სასოფლო სამეურნეო' },
    { value: 'nonagricultural', label: 'არასასოფლო სამეურნეო' },
    { value: 'commercial', label: 'კომერციული' },
    { value: 'special', label: 'სპეციალური' },
    { value: 'investmentconstruction', label: 'საინვესტიციო მშენებლობისთვის' },
    { value: 'arableland', label: 'სახნავ სათესი' },
];

const farmAgriculturalTypes = [
    { value: 'arable', label: 'სახნავი' },
    { value: 'hayfield', label: 'სათიბი' },
    { value: 'pasture', label: 'საძოვარი' },
    { value: 'orchard', label: 'საბაღე' },
    { value: 'vegetablegarden', label: 'საბოსტნე' },
    { value: 'homestead', label: 'საკარმიდამო' },
    { value: 'forestry', label: 'სატყეო' },
];



function Add() {
    const { isLoggedIn, logout } = useAuth();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn == false) {
            alert('გთხოვთ გაიაროთ ავტორიზაცია')
            navigate('/');
        }
    }, [logout])

    const [images, setImages] = useState(Array(15).fill(null));

    const uploadAdImageRefs = useRef([]);

    const handleUploadClick = (index) => {
        uploadAdImageRefs.current[index].click();
    };

    const handleImageChange = (e, index) => {
        const newImages = [...images];
        newImages[index] = e.target.files[0];
        setImages(newImages);
    };

    const handleImageDelete = (index) => {
        const newImages = [...images];
        newImages[index] = null;
        setImages(newImages);
    };

    // Select states
    const [propertyTypeOptionSelect, setPropertyTypeOptionSelect] = useState(null);
    const [transactionTypeOptionSelect, setTransactionTypeOptionSelect] = useState(null);
    const [propertyStatusOptionSelect, setPropertyStatusOptionSelect] = useState(null);
    const [propertyProjectOptionSelect, setPropertyProjectOptionSelect] = useState(null);
    const [propertyProjectClassOptionSelect, setPropertyProjectClassOptionSelect] = useState(null);
    const [propertyConditionOptionSelect, setPropertyConditionOptionSelect] = useState(null);
    const [cityOption, setCityOption] = useState(null);
    const [districtOption, setDistrictOption] = useState(null);
    const [municipalOption, setMunicipalOption] = useState(null);
    const [areaType, setAreaType] = useState(null);
    const [landType, setLandType] = useState(null);
    const [functgionZone, setFunctgionZone] = useState(null);
    const [agriculturalType, setAgriculturalType] = useState(null);
    const [hotelStar, setHotelStar] = useState(null);
    const [eating, setEating] = useState(null);
    const [businesscenterClass, setBusinesscenterClass] = useState(null);
    const [businessIndustry, setBusinessIndustry] = useState(null);
    const [owner, setOwner] = useState(null);
    const [ownerType, setOwnerType] = useState(null);
    const [active, setActive] = useState(null);
    const [farmType, setFarmType] = useState(null);
    const [farmLandType, setFarmLandType] = useState(null);
    const [farmAgriculturalType, setFarmAgriculturalType] = useState(null);

    // Input states
    const [buildingCompany, setBuildingCompany] = useState('');
    const [buildData, setBuildData] = useState('');
    const [propertyPriceGel, setPropertyPriceGel] = useState('');
    const [propertyPriceUsd, setPropertyPriceUsd] = useState('');
    const [propertyPriceEur, setPropertyPriceEur] = useState('');
    const [village, setVillage] = useState('');
    const [address, setAddress] = useState('');
    const [fullArea, setFullArea] = useState('');
    const [roomQuantity, setRoomQuantity] = useState('');
    const [bedRoomQuantity, setBedRoomQuantity] = useState('');
    const [masterBedRoomQuantity, setMasterBedRoomQuantity] = useState('');
    const [floor, setFloor] = useState('');
    const [floorAll, setFloorAll] = useState('');
    const [bathRoomQuantity, setBathRoomQuantity] = useState('');
    const [livingRoomArea, setLivingRoomArea] = useState('');
    const [livingRoomQuantity, setLivingRoomQuantity] = useState('');
    const [studioArea, setStudioArea] = useState('');
    const [kitchenArea, setKitchenArea] = useState('');
    const [kitchenQuantity, setKitchenQuantity] = useState('');
    const [balconyArea, setBalconyArea] = useState('');
    const [balconyQuantity, setBalconyQuantity] = useState('');
    const [closedBalconyArea, setClosedBalconyArea] = useState('');
    const [closedBalconyQuantity, setClosedBalconyQuantity] = useState('');
    const [frenchBalconyArea, setFrenchBalconyArea] = useState('');
    const [frenchBalconyQuantity, setFrenchBalconyQuantity] = useState('');
    const [verandaArea, setVerandaArea] = useState('');
    const [verandaQuantity, setVerandaQuantity] = useState('');
    const [dressingArea, setDressingArea] = useState('');
    const [dressingQuantity, setDressingQuantity] = useState('');
    const [storageArea, setStorageArea] = useState('');
    const [storageQuantity, setStorageQuantity] = useState('');
    const [loftArea, setLoftArea] = useState('');
    const [duplexArea, setDuplexArea] = useState('');
    const [triplexArea, setTriplexArea] = useState('');
    const [summerKitchenrea, setSummerKitchenrea] = useState('');
    const [basementArea, setBasementArea] = useState('');
    const [basementQuantity, setBasementQuantity] = useState('');
    const [loggiaArea, setLoggiaArea] = useState('');
    const [hallArea, setHallArea] = useState('');
    const [constructionArea, setConstructionArea] = useState('');
    const [buildupArea, setBuildupArea] = useState('');
    const [coridorAreaArea, setCoridorArea] = useState('');
    const [antresorArea, setAntresorArea] = useState('');
    const [winterGardenArea, setWinterGardenArea] = useState('');
    const [parkingQuantity, setParkingQuantity] = useState('');
    const [yardParkingQuantity, setYardParkingQuantity] = useState('');
    const [undergroundParkingQuantity, setUndergroundParkingQuantity] = useState('');
    const [parkingPlaceQuantity, setParkingPlaceQuantity] = useState('');
    const [priceParkingQuantity, setPriceParkingQuantity] = useState('');
    const [liftQuantity, setLiftQuantity] = useState('');
    const [bigLiftQuantity, setBigLiftQuantity] = useState('');
    const [fireplaceQuantity, setFireplaceQuantity] = useState('');
    const [gardenArea, setGardenArea] = useState('');
    const [builingCompany, setBuilingCompany] = useState('');
    const [showcaseArea, setShowcaseArea] = useState('');
    const [showcaseQuantity, setShowcaseQuantity] = useState('');
    const [fasadeLength, setFasadeLength] = useState('');
    const [tradeHallArea, setTradeHallArea] = useState('');
    const [tradeHallQuantity, setTradeHallQuantity] = useState('');
    const [landBuildingArea, setLandBuildingArea] = useState('');
    const [hotelRoomsQuantity, setHotelRoomsQuantity] = useState('');
    const [hotelLuxQuantity, setHotelLuxQuantity] = useState('');
    const [hotelActivity, setHotelActivity] = useState('');
    const [otherBusinessIndustry, setOtherBusinessIndustry] = useState('');
    const [businessIdCode, setBusinessIdCode] = useState('');
    const [businessBuildingQuantity, setBusinessBuildingQuantity] = useState('');
    const [businessBuildingArea, setBusinessBuildingArea] = useState('');
    const [businessLandArea, setBusinessLandArea] = useState('');
    const [businessEmployeeQuantity, setBusinessEmployeeQuantity] = useState('');
    const [businessRevenueGel, setBusinessRevenueGel] = useState('');
    const [businessRevenueUsd, setBusinessRevenueUsd] = useState('');
    const [businessRevenueEur, setBusinessRevenueEur] = useState('');
    const [businessSumRevenueGel, setBusinessSumRevenueGel] = useState('');
    const [businessSumRevenueUsd, setBusinessSumRevenueUsd] = useState('');
    const [businessSumRevenueEur, setBusinessSumRevenueEur] = useState('');
    const [otherFarmType, setOtherFarmType] = useState('');
    const [otherFarmBuildingQuantity, setOtherFarmBuildingQuantity] = useState('');
    const [otherFarmBuildingArea, setOtherFarmBuildingArea] = useState('');
    const [otherFarmLakeQuantity, setOtherFarmLakeQuantity] = useState('');
    const [otherFarmLakeArea, setOtherFarmLakeArea] = useState('');
    const [installmentFirstPrice, setInstallmentFirstPrice] = useState('');
    const [installmentMonthlyPrice, setInstallmentMonthlyPrice] = useState('');
    const [installmentLastPrice, setInstallmentLastPrice] = useState('');
    const [installmentPeriod, setInstallmentPeriod] = useState('');
    const [auctionMonthlyPrice, setAuctionMonthlyPrice] = useState('');
    const [auctionAllPrice, setAuctionAllPrice] = useState('');
    const [descriptionGeo, setDescriptionGeo] = useState('');
    const [descriptionEng, setDescriptionEng] = useState('');
    const [descriptionRus, setDescriptionRus] = useState('');

    // Checkbox states
    const [livingRoom, setLivingRoom] = useState(null);
    const [studio, setStudio] = useState(null);
    const [kitchen, setKitchen] = useState(null);
    const [balcony, setBalcony] = useState(null);
    const [balcony2, setBalcony2] = useState(null);
    const [balcony3, setBalcony3] = useState(null);
    const [veranda, setVeranda] = useState(null);
    const [dressing, setDressing] = useState(null);
    const [storage, setStorage] = useState(null);
    const [loft, setLoft] = useState(null);
    const [duplex, setDuplex] = useState(null);
    const [triplex, setTriplex] = useState(null);
    const [summerKitchen, setSummerKitchen] = useState(null);
    const [basement, setBasement] = useState(null);
    const [loggia, setLoggia] = useState(null);
    const [hall, setHall] = useState(null);
    const [construction, setConstruction] = useState(null);
    const [buildup, setBuildup] = useState(null);
    const [corridor, setCorridor] = useState(null);
    const [antresor, setAntresor] = useState(null);
    const [winterGarden, setWinterGarden] = useState(null);
    const [parking, setParking] = useState(null);
    const [parking2, setParking2] = useState(null);
    const [parking3, setParking3] = useState(null);
    const [parking4, setParking4] = useState(null);
    const [parking5, setParking5] = useState(null);
    const [lift, setLift] = useState(null);
    const [lift2, setLift2] = useState(null);
    const [lift3, setLift3] = useState(null);
    const [openPool, setOpenPool] = useState(null);
    const [closedPool, setClosedPool] = useState(null);
    const [fireplace, setFireplace] = useState(null);
    const [hotWater, setHotWater] = useState([]);
    const [heating, setHeating] = useState(null);
    const [general, setGeneral] = useState(null);
    const [furniture, setFurniture] = useState(null);
    const [technic, setTechnic] = useState(null);
    const [security, setSecurity] = useState(null);
    const [smartHome, setSmartHome] = useState(null);
    const [advantage, setAdvantage] = useState(null);
    const [addonSecurity, setAddonSecurity] = useState(null);
    const [near, setNear] = useState(null);
    const [allowed, setAllowed] = useState(null);
    const [garden, setGarden] = useState(null);
    const [fence, setFence] = useState(null);
    const [vine, setVine] = useState(null);
    const [trees, setTrees] = useState(null);
    const [citrus, setCitrus] = useState(null);
    const [leaf1, setLeaf1] = useState(null);
    const [leaf2, setLeaf2] = useState(null);
    const [road, setRoad] = useState(null);
    const [material, setMaterial] = useState(null);
    const [project2, setProject2] = useState(null);
    const [roof, setRoof] = useState(null);
    const [showcase, setShowcase] = useState(null);
    const [facade, setFacade] = useState(null);
    const [tradeHall, setTradeHall] = useState(null);
    const [landBuilding, setLandBuilding] = useState(null);
    const [projectAgree1, setProjectAgree1] = useState(null);
    const [projectAgree2, setProjectAgree2] = useState(null);
    const [comunications, setComunications] = useState(null);
    const [gueastHouse, setGueastHouse] = useState(null);
    const [hostel, setHostel] = useState(null);
    const [building, setBuilding] = useState(null);
    const [technicCar1, setTechnicCar1] = useState(null);
    const [technicCar2, setTechnicCar2] = useState(null);
    const [yearRevenue, setYearRevenue] = useState(null);
    const [sumRevenue, setSumRevenue] = useState(null);
    const [farmBuilding, setFarmBuilding] = useState(null);
    const [farmComunications, setFarmComunications] = useState(null);
    const [addonFarmDetails, setAddonFarmDetails] = useState(null);
    const [farmLake, setFarmLake] = useState(null);

    //load data states
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);





    // Active filter state
    const [activeFilterTab, setActiveFilterTab] = useState(0);

    // Top filter states
    const generalFltr = useRef(null);
    const locationFltr = useRef(null);
    const parametersFltr = useRef(null);
    const amenitiesFltr = useRef(null);
    const advantagesFltr = useRef(null);
    const photosFltr = useRef(null);
    const descriptionFltr = useRef(null);


    // Rent & Auction data states
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [auctionDate, setAuctionDate] = useState(new Date());
    const [rentStartDate, setRentStartDate] = useState(new Date());
    const [rentEndDate, setRentEndDate] = useState(new Date());

    // Cadaster code inputs state
    const [inputs, setInputs] = useState([{ id: 1, value: '' }]);

    // Active type
    const [activeType, setActiveType] = useState('');
    const [activeTransaction, setActiveTransaction] = useState('');


    //show-hide elements depend on change
    const propertyTypeChange = (e) => {
        setActiveType(e.value);
        setPropertyTypeOptionSelect(e.value);
    }

    const transactionTypeChange = (e) => {
        setActiveTransaction(e.value);
        setTransactionTypeOptionSelect(e.value);
    }

    // Top main filters
    const filterTabs = [
        { name: 'ძირითადი', ref: generalFltr },
        { name: 'მდებარეობა', ref: locationFltr },
        { name: 'პარამეტრები', ref: parametersFltr },
        { name: 'კეთილმოწყობა', ref: amenitiesFltr },
        { name: 'უპირატესობები', ref: advantagesFltr },
        { name: 'ფოტოები', ref: photosFltr },
        { name: 'აღწერა', ref: descriptionFltr }
    ];

    const handleTabClick = (index, ref) => {
        setActiveFilterTab(index);
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };


    // Cadaster code inputs
    const handleAddInput = () => {
        const newInput = { id: inputs.length + 1, value: '' };
        setInputs([...inputs, newInput]);
    };

    const handleInputChange = (id, event) => {
        const newInputs = inputs.map(input =>
            input.id === id ? { ...input, value: event.target.value } : input
        );
        setInputs(newInputs);
    };

    const handleDeleteInput = (id) => {
        if (inputs.length > 1) {
            const newInputs = inputs.filter(input => input.id !== id);
            setInputs(newInputs);
        }
    };



    const [formData, setFormData] = useState({});


    const submitForm = async () => {
        
        // Update formData
        const updatedFormData = {
            ...formData,
            'property_type': propertyTypeOptionSelect,
                'transaction_type': transactionTypeOptionSelect,
                'property_status': propertyStatusOptionSelect,
                'project': propertyProjectOptionSelect,
                'class': propertyProjectClassOptionSelect,
                'building_company': buildingCompany,
                'build_date': buildData,
                'start_date': startDate,
                'end_date': endDate,
                'condition': propertyConditionOptionSelect,
                'price_gel': propertyPriceGel,
                'price_usd': propertyPriceUsd,
                'price_eur': propertyPriceEur,
                'city': cityOption,
                'region': districtOption,
                'municipality': municipalOption,
                'village': village,
                'address': address,
                'area': fullArea,
                'rooms': roomQuantity,
                'bedrooms': bedRoomQuantity,
                'masterbedrooms': masterBedRoomQuantity,
                'floor': floor,
                'allfloor': floorAll,
                'bathrooms': bathRoomQuantity,
                'livingroom': livingRoom,
                'livingspace': livingRoomArea,
                'livingamount': livingRoomQuantity,
                'studio': studio,
                'studiospace': studioArea,
                'kitchen': kitchen,
                'kitchenspace': kitchenArea,
                'kitchenamount': kitchenQuantity,
                'openbalcony': balcony,
                'openbalconyspace': balconyArea,
                'openbalconyamount': balconyQuantity,
                'closedbalcony': balcony2,
                'closedbalconyspace': closedBalconyArea,
                'closedbalconyamount': closedBalconyQuantity,
                'frenchbalcony': balcony3,
                'frenchbalconyspace': frenchBalconyArea,
                'frenchbalconyamount': frenchBalconyQuantity,
                'terrace': veranda,
                'terracespace': verandaArea,
                'terraceamount': verandaQuantity,
                'dressingroom': dressing,
                'dressingroomspace': dressingArea,
                'dressingroomamount': dressingQuantity,
                'storage': storage,
                'storagespace': storageArea,
                'storageamount': storageQuantity,
                'loft': loft,
                'loftspace': loftArea,
                'duplex': duplex,
                'duplexspace': duplexArea,
                'triplex': triplex,
                'triplexspace': triplexArea,
                'summerkitchen': summerKitchen,
                'summerkitchenspace': summerKitchenrea,
                'basement': basement,
                'basementspace': basementArea,
                'basementamount': basementQuantity,
                'loggia': loggia,
                'loggiaspace': loggiaArea,
                'hall': hall,
                'hallspace': hallArea,
                'buildup': construction,
                'buildupspace': constructionArea,
                'buildup1': buildup,
                'buildupspace1': buildupArea,
                'commoncorridor': corridor,
                'commoncorridorspace': coridorAreaArea,
                'antresor': antresor,
                'antresorspace': antresorArea,
                'wintergarden': winterGarden,
                'wintergardenspace': winterGardenArea,
                'garage': parking,
                'garageamount': parkingQuantity,
                'yardparking': parking2,
                'yardparkingamount': yardParkingQuantity,
                'undergroundparking': parking3,
                'undergroundparkingamount': undergroundParkingQuantity,
                'parkingspace': parking4,
                'parkingspaceamount': parkingPlaceQuantity,
                'paidparking': parking5,
                'paidparkingamount': priceParkingQuantity,
                'elevator': lift,
                'elevatoramount': liftQuantity,
                'freightelevator': lift2,
                'freightelevatoramount': bigLiftQuantity,
                'personalelevator': lift3,
                'outdoorpool': openPool,
                'Indoorpool': closedPool,
                'fireplace': fireplace,
                'fireplaceamount': fireplaceQuantity,
                'hotwater': hotWater,
                'heating': heating,
                'generalamenities': general,
                'furniture': furniture,
                'technic': technic,
                'security': security,
                'smarthouse': smartHome,
                'additionaladvantages': advantage,
                'additionalsecurity': addonSecurity,
                'nearobject': near,
                'allowed': allowed,
                'yeard': garden,
                'yeardarea': gardenArea,
                'fence': fence,
                'vine': vine,
                'fruit': trees,
                'citrus': citrus,
                'leafy': leaf1,
                'coniferous': leaf2,
                'road': road,
                'buildingmaterial': material,
                'project2': project2,
                'constcompanyname': builingCompany,
                'overlap': roof,
                'typeofspace': areaType,
                'showcase': showcase,
                'showcaseamount': showcaseArea,
                'showcaselength': showcaseQuantity,
                'facade': facade,
                'facadelength': fasadeLength,
                'hall2': tradeHall,
                'hall2amount': tradeHallArea,
                'hall2area': tradeHallQuantity,
                'landtype': landType,
                'functionalarea': functgionZone,
                'agriculturalland': agriculturalType,
                'building': landBuilding,
                'buildingarea': landBuildingArea,
                'agreedproject': projectAgree1,
                'buildingpermission': projectAgree2,
                'communications': comunications,
                'numberofstars': hotelStar,
                'roomamount': hotelRoomsQuantity,
                'luxamount': hotelLuxQuantity,
                'familyhotel': gueastHouse,
                'hostel': hostel,
                'food': eating,
                'activity': hotelActivity,
                'businesscenterclass': businesscenterClass,
                'businessinvestmentcategory': businessIndustry,
                'otherindustry': otherBusinessIndustry,
                'owner': owner,
                'Identificationcode': businessIdCode,
                'building1': building,
                'buildingamount1': businessBuildingQuantity,
                'buildingarea1': businessBuildingArea,
                'propertytype': ownerType,
                'landarea': businessLandArea,
                'numberofemployees': businessEmployeeQuantity,
                'heavyequipment': technicCar1,
                'specechnique': technicCar2,
                'assets': active,
                'annualaccrual': yearRevenue,
                'annualaccrualgel': businessRevenueGel,
                'annualaccrualusd': businessRevenueUsd,
                'annualaccrualeur': businessRevenueEur,
                'annualnetprofit': sumRevenue,
                'annualnetprofitgel': businessSumRevenueGel,
                'annualnetprofitusd': businessSumRevenueUsd,
                'annualnetprofiteur': businessSumRevenueEur,
                'farmtype': farmType,
                'otherfarmtype': otherFarmType,
                'landtype1': farmLandType,
                'agrilandtype': farmAgriculturalType,
                'buildingstructureoffice': farmBuilding,
                'buildingstructureofficeamount': otherFarmBuildingQuantity,
                'buildingstructureofficearea': otherFarmBuildingArea,
                'communications1': farmComunications,
                'additionaldetails': addonFarmDetails,
                'lake': farmLake,
                'lakeamount': otherFarmLakeQuantity,
                'lakearea': otherFarmLakeArea,
                'Initialdepositamount': installmentFirstPrice,
                'monthlydepositamount': installmentMonthlyPrice,
                'finalamounttotal': installmentLastPrice,
                'validityperiod': installmentPeriod,
                'auctiondeadline': auctionDate,
                'monthlydepositamount1': auctionMonthlyPrice,
                'finalamounttotal1': auctionAllPrice,
                'rentalstarttime': rentStartDate,
                'rentalendtime': rentEndDate,
        };
        submitFormData(updatedFormData);
    };

    // Call this function before form submission
    const submitFormData = async (formData) => {
        
        if (sessionStorage.getItem('token')) {
            setLoading(true);
    
            const header = {
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            };
    
            const data = new FormData();
    
            for (const [key, value] of Object.entries(formData)) {
                if (value) { // Only append non-null values
                    data.append(key, value);
                }
            }
    
            images.forEach((image, index) => {
                if (image) {
                    data.append(`image${index + 1}`, image);
                }
            });
    
            try {
                await insert_property(setMessage, data, header);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        } else {
            console.error('Not authorized');
        }
    };



    return (
        <div className='main'>
            <section className="productSection">
                <Container>
                    <Row>

                        <Col xxl={12}>
                            <div className="tabsBox">
                                {filterTabs.map((filterTabs, index) => (
                                    <div
                                        key={index}
                                        className={`singleTab ${activeFilterTab === index ? 'active' : ''}`}
                                        onClick={() => handleTabClick(index, filterTabs.ref)}
                                    >
                                        {filterTabs.name}
                                    </div>
                                ))}
                            </div>
                        </Col>

                        <Col xxl={12} ref={generalFltr}>
                            <div className="cardBox">
                                <div className="cardBoxTitle">
                                    <h4>ძირითადი ინფორმაცია</h4>
                                </div>

                                <Row className="cardBoxFormGroup">

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupSelect mb-3">
                                            <label htmlFor='property-type'>უძრავი ქონების ტიპი</label>
                                            <Select
                                                id='property-type'
                                                value={propertyTypeOptions.find(option => option.value === propertyTypeOptionSelect)}
                                                onChange={(e) => propertyTypeChange(e)}
                                                options={propertyTypeOptions}
                                                placeholder={'აირჩიეთ უძრავი ქონების ტიპი'}
                                            />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupSelect mb-3">
                                            <label htmlFor='transaction-type'>გარიგების ტიპი</label>
                                            <Select
                                                id='transaction-type'
                                                value={transactionTypeOptions.find(option => option.value === transactionTypeOptionSelect)}
                                                onChange={(e) => transactionTypeChange(e)}
                                                options={transactionTypeOptions}
                                                placeholder={'აირჩიეთ გარიგების ტიპი'}
                                            />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupSelect mb-3">
                                            <label htmlFor='property-status'>უძრავი ქონების სტატუსი</label>
                                            <Select
                                                id='property-status'
                                                value={propertyStatusOptions.find(option => option.value === propertyStatusOptionSelect)}
                                                onChange={(e) => setPropertyStatusOptionSelect(e.value)}
                                                options={propertyStatusOptions}
                                                placeholder={'აირჩიეთ უძრავი ქონების სტატუსი'}
                                            />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupSelect mb-3">
                                            <label htmlFor='property-project'>პროექტი</label>
                                            <Select
                                                id='property-project'
                                                value={propertyProjectOptions.find(option => option.value === propertyProjectOptionSelect)}
                                                onChange={(e) => setPropertyProjectOptionSelect(e.value)}
                                                options={propertyProjectOptions}
                                                placeholder={'აირჩიეთ პროექტი'}
                                            />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupSelect mb-3">
                                            <label htmlFor='property-project-class'>პროექტის კლასი</label>
                                            <Select
                                                id='property-project-class'
                                                value={propertyProjectClassOptions.find(option => option.value === propertyProjectClassOptionSelect)}
                                                onChange={(e) => setPropertyProjectClassOptionSelect(e.value)}
                                                options={propertyProjectClassOptions}
                                                placeholder={'აირჩიეთ პროექტის კლასი'}
                                            />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='development-company'>სამშენებლო კომპანიის დასახელება</label>
                                            <input type='text' onInput={(e) => setBuildingCompany(e.target.value)} name='development-company' id='development-company' placeholder='ჩაწერეთ სამშენებლო კომპანიის დასახელება' />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='build-datet'>აშენების თარიღი</label>
                                            <input type='text' onInput={(e) => setBuildData(e.target.value)} name='build-date' id='build-date' placeholder='ჩაწერეთ აშენების თარიღი' />
                                        </div>
                                    </Col>

                                    <Col xxl={2}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='build-datet'>დაწყების თარიღი</label>
                                            <div className="dateBox">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xxl={2}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='build-datet'>დასრულების თარიღი</label>
                                            <div className="dateBox">
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={(date) => setEndDate(date)}
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupSelect mb-3">
                                            <label htmlFor='property-condition'>მდგომარეობა</label>
                                            <Select
                                                id='property-condition'
                                                value={propertyConditionOptions.find(option => option.value === propertyConditionOptionSelect)}
                                                onChange={(e) => setPropertyConditionOptionSelect(e.value)}
                                                options={propertyConditionOptions}
                                                placeholder={'აირჩიეთ მდგომარეობა'}
                                            />
                                        </div>
                                    </Col>

                                    <Col xxl={12} className="mt-1">
                                        <Row>
                                            <Col xxl={12} className="mb-3 mt-3">
                                                <div className="subTitle">ფასი</div>
                                            </Col>
                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='address'>ფასი GEL</label>
                                                    <input type='text' onInput={(e) => setPropertyPriceGel(e.target.value)} name='address' id='address' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='address'>ფასი USD</label>
                                                    <input type='text' onInput={(e) => setPropertyPriceUsd(e.target.value)} name='address' id='address' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='address'>ფასი EUR</label>
                                                    <input type='text' onInput={(e) => setPropertyPriceEur(e.target.value)} name='address' id='address' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                        </Row>

                                    </Col>

                                </Row>
                            </div>
                        </Col>



                        <Col xxl={12} ref={locationFltr}>
                            <div className="cardBox">
                                <div className="cardBoxTitle">
                                    <h4>მდებარეობა</h4>
                                </div>

                                <Row className="cardBoxFormGroup">

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupSelect mb-3">
                                            <label htmlFor='city'>ქალაქი</label>
                                            <Select
                                                id='city'
                                                value={cityOptions.find(option => option.value === cityOption)}
                                                onChange={(e) => setCityOption(e.value)}
                                                options={cityOptions}
                                                placeholder={'აირჩიეთ ქალაქი'}
                                            />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupSelect mb-3">
                                            <label htmlFor='district'>რეგიონი</label>
                                            <Select
                                                id='district'
                                                value={districtOptions.find(option => option.value === districtOption)}
                                                onChange={(e) => setDistrictOption(e.value)}
                                                options={districtOptions}
                                                placeholder={'აირჩიეთ რეგიონი'}
                                            />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupSelect mb-3">
                                            <label htmlFor='municipal'>მუნიციპალიტეტი</label>
                                            <Select
                                                id='municipal'
                                                value={municipalOptions.find(option => option.value === municipalOption)}
                                                onChange={(e) => setMunicipalOption(e.value)}
                                                options={municipalOptions}
                                                placeholder={'აირჩიეთ მუნიციპალიტეტი'}
                                            />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='village'>სოფელი-დაბა</label>
                                            <input type='text' onInput={(e) => setVillage(e.target.value)} name='village' id='village' placeholder='ჩაწერეთ სოფელი-დაბა' />
                                        </div>
                                    </Col>

                                    <Col xxl={8}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='address'>ქუჩის მისამართი</label>
                                            <input type='text' onInput={(e) => setAddress(e.target.value)} name='address' id='address' placeholder='ჩაწერეთ მისამართი' />
                                        </div>
                                    </Col>

                                    <Col xxl={12}>
                                        {inputs.map((input, index) => (
                                            <div className="form-group customFormGroupInput mb-3" key={input.id}>
                                                <label htmlFor={`cadaster-${input.id}`}>საკადასტრო კოდი {input.id}</label>
                                                <input
                                                    type='text'
                                                    name={`cadaster-${input.id}`}
                                                    id={`cadaster-${input.id}`}
                                                    placeholder='ჩაწერეთ საკადასტრო კოდი'
                                                    value={input.value}
                                                    onChange={e => handleInputChange(input.id, e)}
                                                />
                                                {inputs.length > 1 && (
                                                    <button type="button" className="deleteCustomField" onClick={() => handleDeleteInput(input.id)}>Delete</button>
                                                )}
                                            </div>
                                        ))}
                                        <div className="cadasterAddBox">
                                            <button type="button" className="addCustomField" onClick={handleAddInput}>+ საკადასტრო კოდის დამატება</button>
                                        </div>
                                    </Col>



                                </Row>
                            </div>
                        </Col>


                        <Col xxl={12} ref={parametersFltr}>
                            <div className="cardBox">
                                <div className="cardBoxTitle">
                                    <h4>პარამეტრები</h4>
                                </div>

                                <Row className="cardBoxFormGroup">

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='area'>სრული ფართ (მ<sup>2</sup>)</label>
                                            <input type='number' onInput={(e) => setFullArea(e.target.value)} name='area' id='area' placeholder='სრული ფართ' />
                                        </div>
                                    </Col>

                                    <Col xxl={8}>
                                        &nbsp;
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='rooms'>ოთახების რაოდენობა</label>
                                            <input type='number' onInput={(e) => setRoomQuantity(e.target.value)} name='rooms' id='rooms' placeholder='რაოდენობა' />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='bedrooms'>საძინებლების რაოდენობა</label>
                                            <input type='number' onInput={(e) => setBedRoomQuantity(e.target.value)} name='bedrooms' id='bedrooms' placeholder='რაოდენობა' />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='bedrooms'>მასტერ საძინებლების რაოდენობა</label>
                                            <input type='number' onInput={(e) => setMasterBedRoomQuantity(e.target.value)} name='bedrooms' id='bedrooms' placeholder='რაოდენობა' />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='floor'>სართული</label>
                                            <input type='number' onInput={(e) => setFloor(e.target.value)} name='floor' id='floor' placeholder='სართული' />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='floor_all'>სართული სულ</label>
                                            <input type='number' onInput={(e) => setFloorAll(e.target.value)} name='floor_all' id='floor_all' placeholder='სართული სულ' />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='bathrooms'>სველი წერტილების რაოდენობა</label>
                                            <input type='number' onInput={(e) => setBathRoomQuantity(e.target.value)} name='bathrooms' id='bathrooms' placeholder='რაოდენობა' />
                                        </div>
                                    </Col>

                                    <Col xxl={12} className="mb-3 mt-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setLivingRoom(e.target.value); } else { setLivingRoom(null) } }} name="livingroom" /><span className="customCheckboxBox"></span><strong>მისაღები</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setLivingRoomArea(e.target.value)} name='livingroom_area' id='livingroom_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setLivingRoomQuantity(e.target.value)} name='livingroom_quantity' id='livingroom_quantity' placeholder='რაოდენობა' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setStudio(e.target.value); } else { setStudio(null) } }} name="studio" /><span className="customCheckboxBox"></span><strong>სტუდიო</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setStudioArea(e.target.value)} name='studio_area' id='studio_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setKitchen(e.target.value); } else { setKitchen(null) } }} name="kitchen" /><span className="customCheckboxBox"></span><strong>სამზარეულო</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setKitchenArea(e.target.value)} name='kitchen_area' id='kitchen_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setKitchenQuantity(e.target.value)} name='kitchen_quantity' id='kitchen_quantity' placeholder='რაოდენობა' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setBalcony(e.target.value); } else { setBalcony(null) } }} name="balcony" /><span className="customCheckboxBox"></span><strong>ღია აივანი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setBalconyArea(e.target.value)} name='balcony_area' id='balcony_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setBalconyQuantity(e.target.value)} name='balcony_quantity' id='balcony_quantity' placeholder='რაოდენობა' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setBalcony2(e.target.value); } else { setBalcony2(null) } }} name="balcony2" /><span className="customCheckboxBox"></span><strong>დახურული აივანი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setClosedBalconyArea(e.target.value)} name='balcony2_area' id='balcony2_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setClosedBalconyQuantity(e.target.value)} name='balcony2_quantity' id='balcony2_quantity' placeholder='რაოდენობა' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setBalcony3(e.target.value); } else { setBalcony3(null) } }} name="balcony3" /><span className="customCheckboxBox"></span><strong>ფრანგული აივანი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setFrenchBalconyArea(e.target.value)} name='balcony3_area' id='balcony3_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setFrenchBalconyQuantity(e.target.value)} name='balcony3_quantity' id='balcony3_quantity' placeholder='რაოდენობა' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setVeranda(e.target.value); } else { setVeranda(null) } }} name="veranda" /><span className="customCheckboxBox"></span><strong>ვერანდა/ტერასა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setVerandaArea(e.target.value)} name='veranda_area' id='veranda_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setVerandaQuantity(e.target.value)} name='veranda_quantity' id='veranda_quantity' placeholder='რაოდენობა' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setDressing(e.target.value); } else { setDressing(null) } }} name="dressing" /><span className="customCheckboxBox"></span><strong>საგარდერობო ოთახი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setDressingArea(e.target.value)} name='dressing_area' id='dressing_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setDressingQuantity(e.target.value)} name='dressing_quantity' id='dressing_quantity' placeholder='რაოდენობა' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setStorage(e.target.value); } else { setStorage(null) } }} name="storage" /><span className="customCheckboxBox"></span><strong>სათავსო</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setStorageArea(e.target.value)} name='storage_area' id='storage_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setStorageQuantity(e.target.value)} name='storage_quantity' id='storage_quantity' placeholder='რაოდენობა' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setLoft(e.target.value); } else { setLoft(null) } }} name="loft" /><span className="customCheckboxBox"></span><strong>სხვენი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setLoftArea(e.target.value)} name='loft_area' id='loft_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setDuplex(e.target.value); } else { setDuplex(null) } }} name="duplex" /><span className="customCheckboxBox"></span><strong>დუპლექსი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setDuplexArea(e.target.value)} name='duplex_area' id='duplex_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setTriplex(e.target.value); } else { setTriplex(null) } }} name="triplex" /><span className="customCheckboxBox"></span><strong>ტრიპლექსი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setTriplexArea(e.target.value)} name='triplex_area' id='triplex_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setSummerKitchen(e.target.value); } else { setSummerKitchen(null) } }} name="summer_kitchen" /><span className="customCheckboxBox"></span><strong>საზაფხულო სამზარეულო</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setSummerKitchenrea(e.target.value)} name='summer_kitchen_area' id='summer_kitchen_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setBasement(e.target.value); } else { setBasement(null) } }} name="basement" /><span className="customCheckboxBox"></span><strong>სარდაფი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setBasementArea(e.target.value)} name='basement_area' id='basement_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setBasementQuantity(e.target.value)} name='basement_quantity' id='basement_quantity' placeholder='რაოდენობა' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setLoggia(e.target.value); } else { setLoggia(null) } }} name="loggia" /><span className="customCheckboxBox"></span><strong>ლოჯი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setLoggiaArea(e.target.value)} name='loggia_area' id='loggia_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setHall(e.target.value); } else { setHall(null) } }} name="hall" /><span className="customCheckboxBox"></span><strong>ჰოლი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setHallArea(e.target.value)} name='hall_area' id='hall_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setConstruction(e.target.value); } else { setConstruction(null) } }} name="construction" /><span className="customCheckboxBox"></span><strong>დაშენება</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setConstructionArea(e.target.value)} name='construction_area' id='construction_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setBuildup(e.target.value); } else { setBuildup(null) } }} name="buildup" /><span className="customCheckboxBox"></span><strong>მიშენება</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setBuildupArea(e.target.value)} name='buildup_area' id='buildup_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setCorridor(e.target.value); } else { setCorridor(null) } }} name="corridor" /><span className="customCheckboxBox"></span><strong>საერთო კორიდორი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setCoridorArea(e.target.value)} name='corridor_area' id='corridor_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setAntresor(e.target.value); } else { setAntresor(null) } }} name="antresor" /><span className="customCheckboxBox"></span><strong>ანტრესორი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setAntresorArea(e.target.value)} name='antresor_area' id='antresor_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setWinterGarden(e.target.value); } else { setWinterGarden(null) } }} name="winter_garden" /><span className="customCheckboxBox"></span><strong>ზამთრის ბაღი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setWinterGardenArea(e.target.value)} name='winter_garden_area' id='winter_garden_area' placeholder='ფართი კვ.მ' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setParking(e.target.value); } else { setParking(null) } }} name="parking" /><span className="customCheckboxBox"></span><strong>ავტოფარეხი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setParkingQuantity(e.target.value)} name='parking_quantity' id='parking_quantity' placeholder='რაოდენობა' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setParking2(e.target.value); } else { setParking2(null) } }} name="parking2" /><span className="customCheckboxBox"></span><strong>ეზოს პარკინგი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setYardParkingQuantity(e.target.value)} name='parking2_quantity' id='parking2_quantity' placeholder='რაოდენობა' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setParking3(e.target.value); } else { setParking3(null) } }} name="parking3" /><span className="customCheckboxBox"></span><strong>მიწისქვეშა პარკინგი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setUndergroundParkingQuantity(e.target.value)} name='parking3_quantity' id='parking3_quantity' placeholder='რაოდენობა' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setParking4(e.target.value); } else { setParking4(null) } }} name="parking4" /><span className="customCheckboxBox"></span><strong>პარკინგის ადგილი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setParkingPlaceQuantity(e.target.value)} name='parking4_quantity' id='parking4_quantity' placeholder='რაოდენობა' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setParking5(e.target.value); } else { setParking5(null) } }} name="parking5" /><span className="customCheckboxBox"></span><strong>ფასიანი პარკინგი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setPriceParkingQuantity(e.target.value)} name='parking5_quantity' id='parking5_quantity' placeholder='რაოდენობა' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                            </div>
                        </Col>


                        <Col xxl={12} ref={amenitiesFltr}>
                            <div className="cardBox">
                                <div className="cardBoxTitle">
                                    <h4>კეთილმოწყობა</h4>
                                </div>

                                <Row className="cardBoxFormGroup">

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setLift(e.target.value); } else { setLift(null) } }} name="lift" /><span className="customCheckboxBox"></span><strong>სამგზავრო ლიფტი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setLiftQuantity(e.target.value)} name='lift_quantity' id='lift_quantity' placeholder='რაოდენობა' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setLift2(e.target.value); } else { setLift2(null) } }} name="lift2" /><span className="customCheckboxBox"></span><strong>სატვირთო ლიფტი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setBigLiftQuantity(e.target.value)} name='lift2_quantity' id='lift_quantity' placeholder='რაოდენობა' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-4 mt-3">
                                        <Row>
                                            <Col xxl={3}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setLift3(e.target.value); } else { setLift3(null) } }} name="lift3" /><span className="customCheckboxBox"></span><strong>პერსონალური ლიფტი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setOpenPool(e.target.value); } else { setOpenPool(null) } }} name="openPool" /><span className="customCheckboxBox"></span><strong>ღია აუზი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setClosedPool(e.target.value); } else { setClosedPool(null) } }} name="closedPool" /><span className="customCheckboxBox"></span><strong>დახურული აუზი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setFireplace(e.target.value); } else { setFireplace(null) } }} name="fireplace" /><span className="customCheckboxBox"></span><strong>ბუხარი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <input type='number' onInput={(e) => setFireplaceQuantity(e.target.value)} name='fireplace_quantity' id='fireplace_quantity' placeholder='რაოდენობა' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-4 mt-3">
                                        <Row>
                                            <Col xxl={12} className="mb-3 mt-3">
                                                <div className="subTitle">ცხელი წყალი</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="centralheating" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setHotWater([...hotWater, value]); } else { setHotWater(hotWater.filter((item) => item !== value)); } }} name="hotwater1" /><span className="customCheckboxBox"></span><strong>ცენტრალური გათბობის სისტემა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={8} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="gasheater" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setHotWater([...hotWater, value]); } else { setHotWater(hotWater.filter((item) => item !== value)); } }} name="hotwater2" /><span className="customCheckboxBox"></span><strong>გაზის გამაცხელებელი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="tank" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setHotWater([...hotWater, value]); } else { setHotWater(hotWater.filter((item) => item !== value)); } }} name="hotwater3" /><span className="customCheckboxBox"></span><strong>ავზი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={8} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="electricheater" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setHotWater([...hotWater, value]); } else { setHotWater(hotWater.filter((item) => item !== value)); } }} name="hotwater4" /><span className="customCheckboxBox"></span><strong>დენის გამაცხელებელი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="naturalhotwater" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setHotWater([...hotWater, value]); } else { setHotWater(hotWater.filter((item) => item !== value)); } }} name="hotwater5" /><span className="customCheckboxBox"></span><strong>ბუნებრივი ცხელი წყალი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={8} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="solarheater" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setHotWater([...hotWater, value]); } else { setHotWater(hotWater.filter((item) => item !== value)); } }} name="hotwater6" /><span className="customCheckboxBox"></span><strong>მზის გამათბობელი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>

                                    </Col>

                                    <Col xxl={12} className="mb-4 mt-3">
                                        <Row>
                                            <Col xxl={12} className="mb-3 mt-3">
                                                <div className="subTitle">გათბობა</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="centralheating" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setHeating([...heating, value]); } else { setHeating(heating.filter((item) => item !== value)); } }} name="heating1" /><span className="customCheckboxBox"></span><strong>ცენტრალური გათბობა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={8} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="gasheater" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setHeating([...heating, value]); } else { setHeating(heating.filter((item) => item !== value)); } }} name="heating2" /><span className="customCheckboxBox"></span><strong>გაზის გამათბობელი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="floorheating" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setHeating([...heating, value]); } else { setHeating(heating.filter((item) => item !== value)); } }} name="heating3" /><span className="customCheckboxBox"></span><strong>იატაკის გათბობა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={8} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="powerheater" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setHeating([...heating, value]); } else { setHeating(heating.filter((item) => item !== value)); } }} name="heating4" /><span className="customCheckboxBox"></span><strong>დენის გამათბობელი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="chiller" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setHeating([...heating, value]); } else { setHeating(heating.filter((item) => item !== value)); } }} name="heating5" /><span className="customCheckboxBox"></span><strong>ჩილერი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={8} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="solarheater" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setHeating([...heating, value]); } else { setHeating(heating.filter((item) => item !== value)); } }} name="heating6" /><span className="customCheckboxBox"></span><strong>მზის გამათბობელი</strong>
                                                    </label>
                                                </div>
                                            </Col>

                                        </Row>

                                    </Col>

                                    <Col xxl={12} className="mb-4 mt-3">
                                        <Row>
                                            <Col xxl={12} className="mb-3 mt-3">
                                                <div className="subTitle">ზოგადი კეთილმოწყობა</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="naturalgas" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setGeneral([...general, value]); } else { setGeneral(general.filter((item) => item !== value)); } }} name="general1" /><span className="customCheckboxBox"></span><strong>ბუნებრივი აირი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="generator" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setGeneral([...general, value]); } else { setGeneral(general.filter((item) => item !== value)); } }} name="general2" /><span className="customCheckboxBox"></span><strong>გენერატორი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="internet" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setGeneral([...general, value]); } else { setGeneral(general.filter((item) => item !== value)); } }} name="general3" /><span className="customCheckboxBox"></span><strong>ინტერნეტი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="television" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setGeneral([...general, value]); } else { setGeneral(general.filter((item) => item !== value)); } }} name="general4" /><span className="customCheckboxBox"></span><strong>ტელევიზია</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="alarmsystem" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setGeneral([...general, value]); } else { setGeneral(general.filter((item) => item !== value)); } }} name="general5" /><span className="customCheckboxBox"></span><strong>სიგნალიზაცია</strong>
                                                    </label>
                                                </div>
                                            </Col>


                                        </Row>

                                    </Col>

                                    <Col xxl={12} className="mb-4 mt-3">
                                        <Row>
                                            <Col xxl={12} className="mb-3 mt-3">
                                                <div className="subTitle">ავეჯი</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="livingroomfurniture" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFurniture([...furniture, value]); } else { setFurniture(furniture.filter((item) => item !== value)); } }} name="furniture1" /><span className="customCheckboxBox"></span><strong>მისაღები ოთახის ავეჯი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="softfurniture" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFurniture([...furniture, value]); } else { setFurniture(furniture.filter((item) => item !== value)); } }} name="furniture2" /><span className="customCheckboxBox"></span><strong>რბილი ავეჯი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="kitchenfurniture" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFurniture([...furniture, value]); } else { setFurniture(furniture.filter((item) => item !== value)); } }} name="furniture3" /><span className="customCheckboxBox"></span><strong>სამზარეულოს ავეჯი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="bedroomfurniture" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFurniture([...furniture, value]); } else { setFurniture(furniture.filter((item) => item !== value)); } }} name="furniture4" /><span className="customCheckboxBox"></span><strong>საძინებლის ავეჯი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="summerfurniture" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFurniture([...furniture, value]); } else { setFurniture(furniture.filter((item) => item !== value)); } }} name="furniture5" /><span className="customCheckboxBox"></span><strong>საზაფხულო ავეჯი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="officefurniture" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFurniture([...furniture, value]); } else { setFurniture(furniture.filter((item) => item !== value)); } }} name="furniture6" /><span className="customCheckboxBox"></span><strong>საოფისეავეჯი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="childrensfurniture" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFurniture([...furniture, value]); } else { setFurniture(furniture.filter((item) => item !== value)); } }} name="furniture7" /><span className="customCheckboxBox"></span><strong>საბავშვო ავეჯი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="partiallyfurnished" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFurniture([...furniture, value]); } else { setFurniture(furniture.filter((item) => item !== value)); } }} name="furniture8" /><span className="customCheckboxBox"></span><strong>ნაწილობრივ ავეჯი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="unfurnished" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFurniture([...furniture, value]); } else { setFurniture(furniture.filter((item) => item !== value)); } }} name="furniture9" /><span className="customCheckboxBox"></span><strong>ავეჯის გარეშე</strong>
                                                    </label>
                                                </div>
                                            </Col>


                                        </Row>

                                    </Col>

                                    <Col xxl={12} className="mb-4 mt-3">
                                        <Row>
                                            <Col xxl={12} className="mb-3 mt-3">
                                                <div className="subTitle">ტექნიკა</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="television" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik1" /><span className="customCheckboxBox"></span><strong>ტელევიზორი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="refrigerator" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik2" /><span className="customCheckboxBox"></span><strong>მაცივარი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="microwaveoven" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik3" /><span className="customCheckboxBox"></span><strong>მიკროტალღური ღუმელი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="dishwasher" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik4" /><span className="customCheckboxBox"></span><strong>ჭურჭლის სარეცხი მანქანა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="washingmachine" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik5" /><span className="customCheckboxBox"></span><strong>სარეცხი მანქანა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="airconditioner" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik6" /><span className="customCheckboxBox"></span><strong>კონდინციონერი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="stereosystem" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik7" /><span className="customCheckboxBox"></span><strong>მუსიკალური ცენტრი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="rangehood" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik8" /><span className="customCheckboxBox"></span><strong>გამწოვი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="stove" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik9" /><span className="customCheckboxBox"></span><strong>გაზქურა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="vacuumcleaner" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik10" /><span className="customCheckboxBox"></span><strong>მტვერსასრუტი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="iron" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik11" /><span className="customCheckboxBox"></span><strong>უთო</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="homecinema" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik12" /><span className="customCheckboxBox"></span><strong>კინოთეატრი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="dryer" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik13" /><span className="customCheckboxBox"></span><strong>საშრობი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="coffeemaker" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik14" /><span className="customCheckboxBox"></span><strong>ყავის აპარატი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="electrickettle" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik15" /><span className="customCheckboxBox"></span><strong>ელექტრო ჩაიდანი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="toaster" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik16" /><span className="customCheckboxBox"></span><strong>ტოსტერი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="juicer" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik17" /><span className="customCheckboxBox"></span><strong>წვენსაწური</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="mixer" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik18" /><span className="customCheckboxBox"></span><strong>მიქსერი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="blender" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik19" /><span className="customCheckboxBox"></span><strong>ბლენდერი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="meatgrinder" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik20" /><span className="customCheckboxBox"></span><strong>ხორცის საკეპი მანქანა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="computer" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik21" /><span className="customCheckboxBox"></span><strong>კომპიუტერი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="printerscannerxerox" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik22" /><span className="customCheckboxBox"></span><strong>პრინტერი / სკანერი/ ქსეროქსი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="safe" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik23" /><span className="customCheckboxBox"></span><strong>სეიფი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="fitnessequipment" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setTechnic([...technic, value]); } else { setTechnic(technic.filter((item) => item !== value)); } }} name="technik24" /><span className="customCheckboxBox"></span><strong>სპორტული ტრენაჟორები</strong>
                                                    </label>
                                                </div>
                                            </Col>


                                        </Row>

                                    </Col>


                                </Row>
                            </div>
                        </Col>

                        <Col xxl={12} ref={advantagesFltr}>
                            <div className="cardBox">
                                <div className="cardBoxTitle">
                                    <h4>უპირატესობები</h4>
                                </div>

                                <Row className="cardBoxFormGroup">

                                    <Col xxl={12} className="mb-4">
                                        <Row>
                                            <Col xxl={12} className="mb-3">
                                                <div className="subTitle">დაცვის სერვისი</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="alarmsystem" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setSecurity([...security, value]); } else { setSecurity(security.filter((item) => item !== value)); } }} name="security1" /><span className="customCheckboxBox"></span><strong>სიგნალიზაცია</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="livesecurity" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setSecurity([...security, value]); } else { setSecurity(security.filter((item) => item !== value)); } }} name="security2" /><span className="customCheckboxBox"></span><strong>ცოცხალი დაცვა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="cctvcameras" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setSecurity([...security, value]); } else { setSecurity(security.filter((item) => item !== value)); } }} name="security3" /><span className="customCheckboxBox"></span><strong>ვიდეო კამერები</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="entranceaccesssystem" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setSecurity([...security, value]); } else { setSecurity(security.filter((item) => item !== value)); } }} name="security4" /><span className="customCheckboxBox"></span><strong>სადარბაზოს დაშვების სისტემა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="gatedaccesssystem" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setSecurity([...security, value]); } else { setSecurity(security.filter((item) => item !== value)); } }} name="security5" /><span className="customCheckboxBox"></span><strong>ჩაკეტილი ეზოს დაშვების სისტემა</strong>
                                                    </label>
                                                </div>
                                            </Col>


                                        </Row>

                                    </Col>

                                    <Col xxl={12} className="mb-4 mt-3">
                                        <Row>
                                            <Col xxl={12} className="mb-3 mt-3">
                                                <div className="subTitle">ჭკვიანი სახლი</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="controlpanels" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setSmartHome([...smartHome, value]); } else { setSmartHome(smartHome.filter((item) => item !== value)); } }} name="smarthome1" /><span className="customCheckboxBox"></span><strong>მართვის პანელები</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="securitysystems" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setSmartHome([...smartHome, value]); } else { setSmartHome(smartHome.filter((item) => item !== value)); } }} name="smarthome2" /><span className="customCheckboxBox"></span><strong>უსაფრთხოების სისტემები</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="smartcamera" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setSmartHome([...smartHome, value]); } else { setSmartHome(smartHome.filter((item) => item !== value)); } }} name="smarthome3" /><span className="customCheckboxBox"></span><strong>ჭკვიანი კამერა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="curtainblind" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setSmartHome([...smartHome, value]); } else { setSmartHome(smartHome.filter((item) => item !== value)); } }} name="smarthome4" /><span className="customCheckboxBox"></span><strong>ფარდა ჟალუზი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="lightingautomation" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setSmartHome([...smartHome, value]); } else { setSmartHome(smartHome.filter((item) => item !== value)); } }} name="smarthome5" /><span className="customCheckboxBox"></span><strong>განათების ავტომატიზირება</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="smartlock" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setSmartHome([...smartHome, value]); } else { setSmartHome(smartHome.filter((item) => item !== value)); } }} name="smarthome6" /><span className="customCheckboxBox"></span><strong>ჭკვიანი საკეტი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="householdappliancecontrol" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setSmartHome([...smartHome, value]); } else { setSmartHome(smartHome.filter((item) => item !== value)); } }} name="smarthome7" /><span className="customCheckboxBox"></span><strong>საყოფაცხოვრებო ტექნიკის მართვა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="audiosystem" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setSmartHome([...smartHome, value]); } else { setSmartHome(smartHome.filter((item) => item !== value)); } }} name="smarthome8" /><span className="customCheckboxBox"></span><strong>აუდიო სისტემა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="smartintercom" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setSmartHome([...smartHome, value]); } else { setSmartHome(smartHome.filter((item) => item !== value)); } }} name="smarthome9" /><span className="customCheckboxBox"></span><strong>ჭკვიანი დომოფონი</strong>
                                                    </label>
                                                </div>
                                            </Col>


                                        </Row>

                                    </Col>


                                    <Col xxl={12} className="mb-4">
                                        <Row>
                                            <Col xxl={12} className="mb-3">
                                                <div className="subTitle">დამატებითი უპირატესობები</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="organizedinfrastructure" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage1" /><span className="customCheckboxBox"></span><strong>მოწესრიგებული ინფრასტრუქტურა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="panoramicview" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage2" /><span className="customCheckboxBox"></span><strong>პანორამული ხედი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="cityview" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage3" /><span className="customCheckboxBox"></span><strong>ხედი ქალაქზე</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="seaview" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage4" /><span className="customCheckboxBox"></span><strong>ხედი ზღვაზე</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="mountainview" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage5" /><span className="customCheckboxBox"></span><strong>ხედი მთაზე</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="riverview" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage6" /><span className="customCheckboxBox"></span><strong>ხედი მდინარეზე</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="lakeview" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage7" /><span className="customCheckboxBox"></span><strong>ხედი ტბაზე</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="courtyardview" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage8" /><span className="customCheckboxBox"></span><strong>ხედი ეზოში</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="bright" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage9" /><span className="customCheckboxBox"></span><strong>ნათელი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="stainedglasswindowdoor" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage10" /><span className="customCheckboxBox"></span><strong>ვიტრაჟული კარ-ფანჯარა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="landscaping" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage11" /><span className="customCheckboxBox"></span><strong>გამწვანება</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="childrenspace" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage12" /><span className="customCheckboxBox"></span><strong>საბავშვო სივრცე</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="sportsfield" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage13" /><span className="customCheckboxBox"></span><strong>სპორტული მოედანი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="concierge" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage14" /><span className="customCheckboxBox"></span><strong>კონსიაჟი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="coworkingspace" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage15" /><span className="customCheckboxBox"></span><strong>საერთო სამუშაო სივრცე</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="businesslobby" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage16" /><span className="customCheckboxBox"></span><strong>ბიზნეს ლობი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="cafeteria" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage17" /><span className="customCheckboxBox"></span><strong>კაფეტერია</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="restaurant" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage18" /><span className="customCheckboxBox"></span><strong>რესტორანი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="conferencehall" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage19" /><span className="customCheckboxBox"></span><strong>საკონფერენციო დარბაზი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="eventhall" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage20" /><span className="customCheckboxBox"></span><strong>ივენთ ჰოლი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="commercialspace" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage21" /><span className="customCheckboxBox"></span><strong>კომერციული ფართი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="residentiallobby" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage22" /><span className="customCheckboxBox"></span><strong>საცხოივრებელი ლობი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="freewifi" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage23" /><span className="customCheckboxBox"></span><strong>უფასო Wifi</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="laundry" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage24" /><span className="customCheckboxBox"></span><strong>სამრეცხაო</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="spacenter" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage25" /><span className="customCheckboxBox"></span><strong>სპა ცენტრი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="sauna" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage26" /><span className="customCheckboxBox"></span><strong>საუნა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="fitnessroom" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage27" /><span className="customCheckboxBox"></span><strong>ფიტნეს დარბაზი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="tenniscourts" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage28" /><span className="customCheckboxBox"></span><strong>კორტები</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="golfcourse" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage29" /><span className="customCheckboxBox"></span><strong>გოლფის მოედანი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="multifunctional" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage30" /><span className="customCheckboxBox"></span><strong>მულტიფუნქციური</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="energyefficient" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage31" /><span className="customCheckboxBox"></span><strong>ენერგო ეფექტური</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="modernarchitecture" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage32" /><span className="customCheckboxBox"></span><strong>თანამედროვე არქიტექტურა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="glazedbalconies" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage33" /><span className="customCheckboxBox"></span><strong>შემინული აივნები</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="jacuzzi" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage34" /><span className="customCheckboxBox"></span><strong>ჯაკუზი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="showercabinplusbathtub" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAdvantage([...advantage, value]); } else { setAdvantage(advantage.filter((item) => item !== value)); } }} name="advantage35" /><span className="customCheckboxBox"></span><strong>დუშ კაბინა + ვანა</strong>
                                                    </label>
                                                </div>
                                            </Col>


                                        </Row>

                                    </Col>


                                    <Col xxl={12} className="mb-4">
                                        <Row>
                                            <Col xxl={12} className="mb-3">
                                                <div className="subTitle">დამატებითი უსაფრთხოება</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="evacuationstaircase" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAddonSecurity([...addonSecurity, value]); } else { setAddonSecurity(addonSecurity.filter((item) => item !== value)); } }} name="security6" /><span className="customCheckboxBox"></span><strong>საევაკუაციო კიბე</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="firesafetyssystem" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAddonSecurity([...addonSecurity, value]); } else { setAddonSecurity(addonSecurity.filter((item) => item !== value)); } }} name="security7" /><span className="customCheckboxBox"></span><strong>ხანძრის საწინააღმდეგო სისტემა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="codetector" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAddonSecurity([...addonSecurity, value]); } else { setAddonSecurity(addonSecurity.filter((item) => item !== value)); } }} name="security8" /><span className="customCheckboxBox"></span><strong>CO დეტექტორი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="smokedetector" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAddonSecurity([...addonSecurity, value]); } else { setAddonSecurity(addonSecurity.filter((item) => item !== value)); } }} name="security9" /><span className="customCheckboxBox"></span><strong>კვამლის დეტექტორი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>


                                    <Col xxl={12} className="mb-4">
                                        <Row>
                                            <Col xxl={12} className="mb-3">
                                                <div className="subTitle">ობიექტთან ახლოს</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="citycenter" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near1" /><span className="customCheckboxBox"></span><strong>ქალაქის ცენტრი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="busstop" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near2" /><span className="customCheckboxBox"></span><strong>ავტობუსის გაჩერება</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="shoppingcenter" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near3" /><span className="customCheckboxBox"></span><strong>სავაჭრო ცენტრი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="school" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near4" /><span className="customCheckboxBox"></span><strong>სკოლა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="kindergarten" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near5" /><span className="customCheckboxBox"></span><strong>საბავშვო ბაღი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="metrosstation" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near6" /><span className="customCheckboxBox"></span><strong>მეტროსადგური</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="cinema" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near7" /><span className="customCheckboxBox"></span><strong>კინოთეატრი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="square" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near8" /><span className="customCheckboxBox"></span><strong>სკვერი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="museum" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near9" /><span className="customCheckboxBox"></span><strong>მუზეუმი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="restaurant" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near10" /><span className="customCheckboxBox"></span><strong>რესტორანი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="supermarket" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near11" /><span className="customCheckboxBox"></span><strong>სუპერმარკეტი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="pharmacy" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near12" /><span className="customCheckboxBox"></span><strong>აფთიაქი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="hospital" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near13" /><span className="customCheckboxBox"></span><strong>საავადმყოფო</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="railwaystation" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near14" /><span className="customCheckboxBox"></span><strong>რკინიგზის სადგური</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="airport" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near15" /><span className="customCheckboxBox"></span><strong>აეროპორტი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="sea" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near16" /><span className="customCheckboxBox"></span><strong>ზღვა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="river" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near17" /><span className="customCheckboxBox"></span><strong>მდინარე</strong>
                                                    </label>
                                                </div>
                                            </Col>

                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="lake" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near18" /><span className="customCheckboxBox"></span><strong>ტბა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="naturereserve" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setNear([...near, value]); } else { setNear(near.filter((item) => item !== value)); } }} name="near19" /><span className="customCheckboxBox"></span><strong>ნაკრძალი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-4">
                                        <Row>
                                            <Col xxl={12} className="mb-3">
                                                <div className="subTitle">დაშვებულია</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="eventhosting" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAllowed([...allowed, value]); } else { setAllowed(allowed.filter((item) => item !== value)); } }} name="allowed1" /><span className="customCheckboxBox"></span><strong>წვეულების გამართვა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="pets" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAllowed([...allowed, value]); } else { setAllowed(allowed.filter((item) => item !== value)); } }} name="allowed2" /><span className="customCheckboxBox"></span><strong>შინაური ცხოველები</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="smoking" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAllowed([...allowed, value]); } else { setAllowed(allowed.filter((item) => item !== value)); } }} name="allowed3" /><span className="customCheckboxBox"></span><strong>მოწევა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                            </div>
                        </Col>


                        {
                            activeType == 'house' || activeType == 'townhouse' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>კერძო სახლის / თაუნ ჰაუსის დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={12}>
                                                <Row>

                                                    <Col xxl={12} className="mb-3">
                                                        <Row>
                                                            <Col xxl={2}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setGarden(e.target.value); } else { setGarden(null) } }} name="garden" /><span className="customCheckboxBox"></span><strong>ეზო</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setGardenArea(e.target.value)} name='garden_area' id='garden_area' placeholder='ეზოს ფართი კვ.მ' />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mb-2">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setFence(e.target.value); } else { setFence(null) } }} name="fence" /><span className="customCheckboxBox"></span><strong>ღობე</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-2">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setVine(e.target.value); } else { setVine(null) } }} name="vine" /><span className="customCheckboxBox"></span><strong>ვაზი</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-2">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setTrees(e.target.value); } else { setTrees(null) } }} name="trees" /><span className="customCheckboxBox"></span><strong>ხეხილი</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-2">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setCitrus(e.target.value); } else { setCitrus(null) } }} name="citrus" /><span className="customCheckboxBox"></span><strong>ციტრუსი</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-2">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setLeaf1(e.target.value); } else { setLeaf1(null) } }} name="leaf1" /><span className="customCheckboxBox"></span><strong>ფოთლოვანი</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-2">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setLeaf2(e.target.value); } else { setLeaf2(null) } }} name="leaf2" /><span className="customCheckboxBox"></span><strong>წიწვოვანი</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-4 mt-3">
                                                        <Row>
                                                            <Col xxl={12} className="mb-3">
                                                                <div className="subTitle">გზა</div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="asphalted" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setRoad([...road, value]); } else { setRoad(road.filter((item) => item !== value)); } }} name="road1" /><span className="customCheckboxBox"></span><strong>ასფალტიანი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="graveled" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setRoad([...road, value]); } else { setRoad(road.filter((item) => item !== value)); } }} name="road2" /><span className="customCheckboxBox"></span><strong>მოხრეშილი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="soil" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setRoad([...road, value]); } else { setRoad(road.filter((item) => item !== value)); } }} name="road3" /><span className="customCheckboxBox"></span><strong>გრუნტი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mb-4">
                                                        <Row>
                                                            <Col xxl={12} className="mb-3">
                                                                <div className="subTitle">აშენების მასალა</div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setMaterial([...material, value]); } else { setMaterial(material.filter((item) => item !== value)); } }} name="material1" /><span className="customCheckboxBox"></span><strong>ბლოკი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setMaterial([...material, value]); } else { setMaterial(material.filter((item) => item !== value)); } }} name="material2" /><span className="customCheckboxBox"></span><strong>აგური</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setMaterial([...material, value]); } else { setMaterial(material.filter((item) => item !== value)); } }} name="material3" /><span className="customCheckboxBox"></span><strong>ქვა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setMaterial([...material, value]); } else { setMaterial(material.filter((item) => item !== value)); } }} name="material4" /><span className="customCheckboxBox"></span><strong>ხე</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setMaterial([...material, value]); } else { setMaterial(material.filter((item) => item !== value)); } }} name="material5" /><span className="customCheckboxBox"></span><strong>პემზის ბლოკი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setMaterial([...material, value]); } else { setMaterial(material.filter((item) => item !== value)); } }} name="material6" /><span className="customCheckboxBox"></span><strong>რკინა-ბეტონი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setMaterial([...material, value]); } else { setMaterial(material.filter((item) => item !== value)); } }} name="material7" /><span className="customCheckboxBox"></span><strong>კომბინირებული</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mb-4">
                                                        <Row>
                                                            <Col xxl={12} className="mb-3">
                                                                <div className="subTitle">პროექტი</div>
                                                            </Col>
                                                            <Col xxl={12} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setProject2([...project2, value]); } else { setProject2(project2.filter((item) => item !== value)); } }} name="individualProject" /><span className="customCheckboxBox"></span><strong>ინდივიადუალური</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={12} className="mb-3">
                                                                <Row>
                                                                    <Col xxl={2}>
                                                                        <div className="customLabelCheck">
                                                                            <label className="customCheckbox">
                                                                                <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setProject2([...project2, value]); } else { setProject2(project2.filter((item) => item !== value)); } }} name="companyProject" /><span className="customCheckboxBox"></span><strong>პროექტი</strong>
                                                                            </label>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xxl={10}>
                                                                        <div className="form-group customFormGroupInput">
                                                                            <input type='number' onInput={(e) => setBuilingCompany(e.target.value)} name='garden_area' id='garden_area' placeholder='სამშენებლო კომპანიის დასახელება' />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col xxl={12} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setProject2([...project2, value]); } else { setProject2(project2.filter((item) => item !== value)); } }} name="notStandard" /><span className="customCheckboxBox"></span><strong>არასტანდარტული</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12}>
                                                        <Row>
                                                            <Col xxl={12} className="mb-3">
                                                                <div className="subTitle">გადახურვა</div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setRoof([...roof, value]); } else { setRoof(roof.filter((item) => item !== value)); } }} name="roof1" /><span className="customCheckboxBox"></span><strong>ბრტყელი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setRoof([...roof, value]); } else { setRoof(roof.filter((item) => item !== value)); } }} name="roof2" /><span className="customCheckboxBox"></span><strong>კონუსებური</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setRoof([...roof, value]); } else { setRoof(roof.filter((item) => item !== value)); } }} name="roof3" /><span className="customCheckboxBox"></span><strong>თუნუქის გადახურვა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setRoof([...roof, value]); } else { setRoof(roof.filter((item) => item !== value)); } }} name="roof4" /><span className="customCheckboxBox"></span><strong>ტოლით დაგახურვა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setRoof([...roof, value]); } else { setRoof(roof.filter((item) => item !== value)); } }} name="roof5" /><span className="customCheckboxBox"></span><strong>კრამიტის სახურავი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>



                                                </Row>

                                            </Col>
                                        </Row>

                                    </div>
                                </Col>
                                :

                                ''
                        }



                        {
                            activeType == 'comercial' || activeType == 'office' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>კომერციული / საოფისე ფართის დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={12}>
                                                <Row>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='area-type'>ფართის ტიპი</label>
                                                            <Select
                                                                id='area-type'
                                                                value={areaTypes.find(option => option.value === areaType)}
                                                                onChange={(e) => setAreaType(e.value)}
                                                                options={areaTypes}
                                                                placeholder={'აირჩიეთ ტიპი'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-3">
                                                        <Row>
                                                            <Col xxl={2}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setShowcase(e.target.value); } else { setShowcase(null) } }} name="showcase" /><span className="customCheckboxBox"></span><strong>ვიტრინა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setShowcaseArea(e.target.value)} name='showcase_quantity' id='showcase_quantity' placeholder='რაოდენობა' />
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setShowcaseQuantity(e.target.value)} name='showcase_length' id='showcase_length' placeholder='სიგრძე (სმ)' />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mb-3">
                                                        <Row>
                                                            <Col xxl={2}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setFacade(e.target.value); } else { setFacade(null) } }} name="facade" /><span className="customCheckboxBox"></span><strong>ფასადი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setFasadeLength(e.target.value)} name='facade_length' id='facade_length' placeholder='სიგრძე (სმ)' />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mb-3">
                                                        <Row>
                                                            <Col xxl={2}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setTradeHall(e.target.value); } else { setTradeHall(null) } }} name="tradehall" /><span className="customCheckboxBox"></span><strong>დარბაზი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setTradeHallArea(e.target.value)} name='tradehalle_quantity' id='tradehalle_quantity' placeholder='რაოდენობა' />
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setTradeHallQuantity(e.target.value)} name='tradehall_length' id='tradehall_length' placeholder='ფართი (კვ.მ)' />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                </Row>

                                            </Col>
                                        </Row>

                                    </div>
                                </Col>
                                :

                                ''
                        }


                        {
                            activeType == 'land' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>მიწის დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={12}>
                                                <Row>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='land-type'>მიწის ტიპი</label>
                                                            <Select
                                                                id='land-type'
                                                                value={landTypes.find(option => option.value === landType)}
                                                                onChange={(e) => setLandType(e.value)}
                                                                options={landTypes}
                                                                placeholder={'აირჩიეთ ტიპი'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='functgion-zone'>ფუნქციური ზონა</label>
                                                            <Select
                                                                id='functgion-zone'
                                                                value={functgionZones.find(option => option.value === functgionZone)}
                                                                onChange={(e) => setFunctgionZone(e.value)}
                                                                options={functgionZones}
                                                                placeholder={'აირჩიეთ ზონა'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='agricultural-type'>სასოფლოს სამეურნეო მიწის ტიპი</label>
                                                            <Select
                                                                id='agricultural-type'
                                                                value={agriculturalTypes.find(option => option.value === agriculturalType)}
                                                                onChange={(e) => setAgriculturalType(e.value)}
                                                                options={agriculturalTypes}
                                                                placeholder={'აირჩიეთ ტიპი'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-3 mt-3">
                                                        <Row>
                                                            <Col xxl={2}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setLandBuilding(e.target.value); } else { setLandBuilding(null) } }} name="landbuilding" /><span className="customCheckboxBox"></span><strong>შენობა ნაგებობა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setLandBuildingArea(e.target.value)} name='landbuilding_area' id='landbuilding_area' placeholder='სრული ფართი (კვ.მ)' />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={4} className="mb-3 mt-3">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setProjectAgree1(e.target.value); } else { setProjectAgree1(null) } }} name="projectagree1" /><span className="customCheckboxBox"></span><strong>შეთანხმებული პროექტი</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4} className="mb-3 mt-3">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setProjectAgree2(e.target.value); } else { setProjectAgree2(null) } }} name="projectagree2" /><span className="customCheckboxBox"></span><strong>მშენებლობის ნებართვა</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mt-3">
                                                        <Row>
                                                            <Col xxl={12} className="mb-3">
                                                                <div className="subTitle">კომუნიკაციები</div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setComunications([...comunications, value]); } else { setComunications(comunications.filter((item) => item !== value)); } }} name="comunications1" /><span className="customCheckboxBox"></span><strong>გზა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setComunications([...comunications, value]); } else { setComunications(comunications.filter((item) => item !== value)); } }} name="comunications2" /><span className="customCheckboxBox"></span><strong>დენი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setComunications([...comunications, value]); } else { setComunications(comunications.filter((item) => item !== value)); } }} name="comunications3" /><span className="customCheckboxBox"></span><strong>წყალი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setComunications([...comunications, value]); } else { setComunications(comunications.filter((item) => item !== value)); } }} name="comunications4" /><span className="customCheckboxBox"></span><strong>გაზი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setComunications([...comunications, value]); } else { setComunications(comunications.filter((item) => item !== value)); } }} name="comunications5" /><span className="customCheckboxBox"></span><strong>კოლექტორი-კანალიზაცია</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setComunications([...comunications, value]); } else { setComunications(comunications.filter((item) => item !== value)); } }} name="comunications6" /><span className="customCheckboxBox"></span><strong>ჭა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setComunications([...comunications, value]); } else { setComunications(comunications.filter((item) => item !== value)); } }} name="comunications7" /><span className="customCheckboxBox"></span><strong>სერვიტუტი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setComunications([...comunications, value]); } else { setComunications(comunications.filter((item) => item !== value)); } }} name="comunications8" /><span className="customCheckboxBox"></span><strong>პირველი ზოლი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setComunications([...comunications, value]); } else { setComunications(comunications.filter((item) => item !== value)); } }} name="comunications9" /><span className="customCheckboxBox"></span><strong>მეორე ზოლი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setComunications([...comunications, value]); } else { setComunications(comunications.filter((item) => item !== value)); } }} name="comunications10" /><span className="customCheckboxBox"></span><strong>მესამე ზოლი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setComunications([...comunications, value]); } else { setComunications(comunications.filter((item) => item !== value)); } }} name="comunications11" /><span className="customCheckboxBox"></span><strong>ღობე</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>


                                                </Row>

                                            </Col>
                                        </Row>

                                    </div>
                                </Col>
                                :
                                ''
                        }



                        {
                            activeType == 'hotel' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>სასტუმროს დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={12}>
                                                <Row>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='hotel-star'>ვარსკვლავების რაოდენობა</label>
                                                            <Select
                                                                id='hotel-star'
                                                                value={hotelStars.find(option => option.value === hotelStar)}
                                                                onChange={(e) => setHotelStar(e.value)}
                                                                options={hotelStars}
                                                                placeholder={'აირჩიეთ ვარსკვლავი'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupInput">
                                                            <label htmlFor='rooms_quantity'>ნომრების რაოდენობა</label>
                                                            <input type='number' onInput={(e) => setHotelRoomsQuantity(e.target.value)} name='rooms_quantity' id='rooms_quantity' placeholder='რაოდენობა' />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupInput">
                                                            <label htmlFor='lux_quantity'>ლუქსების რაოდენობა</label>
                                                            <input type='number' onInput={(e) => setHotelLuxQuantity(e.target.value)} name='lux_quantity' id='lux_quantity' placeholder='რაოდენობა' />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4} className="mb-3 mt-3">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setGueastHouse(e.target.value); } else { setGueastHouse(null) } }} name="guesthouse" /><span className="customCheckboxBox"></span><strong>საოჯახო სასტუმრო</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={8} className="mb-3 mt-3">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setHostel(e.target.value); } else { setHostel(null) } }} name="hostel" /><span className="customCheckboxBox"></span><strong>ჰოსტელი</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={3}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='eating'>კვება</label>
                                                            <Select
                                                                id='eating'
                                                                value={eatings.find(option => option.value === eating)}
                                                                onChange={(e) => setEating(e.value)}
                                                                options={eatings}
                                                                placeholder={'აირჩიეთ კვება'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={9}>
                                                        <div className="form-group customFormGroupInput">
                                                            <label htmlFor='rooms_activity'>აქტივობა</label>
                                                            <input type='text' onInput={(e) => setHotelActivity(e.target.value)} name='rooms_activity' id='rooms_activity' placeholder='ჩაწერეთ აქტივობის ფორმები' />
                                                        </div>
                                                    </Col>


                                                </Row>

                                            </Col>
                                        </Row>

                                    </div>
                                </Col>
                                :
                                ''
                        }


                        {
                            activeType == 'businesscenter' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>ბიზნესცენტრის დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={12}>
                                                <div className="form-group customFormGroupSelect mb-3">
                                                    <label htmlFor='businesscenter-class'>ბიზნესცენტრის კლასი</label>
                                                    <Select
                                                        id='businesscenter-class'
                                                        value={businesscenterClasses.find(option => option.value === businesscenterClass)}
                                                        onChange={(e) => setBusinesscenterClass(e.value)}
                                                        options={businesscenterClasses}
                                                        placeholder={'აირჩიეთ კლასი'}
                                                    />
                                                </div>
                                            </Col>

                                        </Row>

                                    </div>
                                </Col>
                                :
                                ''
                        }


                        {
                            activeType == 'business' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>ბიზნეს ინვესტიციის დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={12}>
                                                <Row>

                                                    <Col xxl={3}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='business-industry'>ბიზნეს ინვესტიციის კატეგორია</label>
                                                            <Select
                                                                id='business-industry'
                                                                value={businessIndustryes.find(option => option.value === businessIndustry)}
                                                                onChange={(e) => setBusinessIndustry(e.value)}
                                                                options={businessIndustryes}
                                                                placeholder={'აირჩიეთ კატეგორია'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={3}>
                                                        <div className="form-group customFormGroupInput">
                                                            <label htmlFor='other-business-industry'>სხვა ინდუსტრია</label>
                                                            <input type='text' onInput={(e) => setOtherBusinessIndustry(e.target.value)} name='other-business-industry' id='other-business-industry' placeholder='ინდუსტრია' />
                                                        </div>
                                                    </Col>


                                                    <Col xxl={3}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='owner'>მესაკუთრე</label>
                                                            <Select
                                                                id='owner'
                                                                value={owners.find(option => option.value === owner)}
                                                                onChange={(e) => setOwner(e.value)}
                                                                options={owners}
                                                                placeholder={'აირჩიეთ მესაკუთრის ტიპი'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={3}>
                                                        <div className="form-group customFormGroupInput">
                                                            <label htmlFor='id_code'>საიდენტიფიკაციო კოდი</label>
                                                            <input type='text' onInput={(e) => setBusinessIdCode(e.target.value)} name='id_code' id='id_code' placeholder='კოდი' />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-3 mt-3">
                                                        <Row>
                                                            <Col xxl={2}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setBuilding(e.target.value); } else { setBuilding(null) } }} name="building" /><span className="customCheckboxBox"></span><strong>შენობა ნაგებობა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setBusinessBuildingQuantity(e.target.value)} name='building_quantity' id='building_quantity' placeholder='რაოდენობა' />
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setBusinessBuildingArea(e.target.value)} name='building_area' id='building_area' placeholder='სრული ფართი (კვ.მ)' />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='owner-type'>საკუთრების ტიპი</label>
                                                            <Select
                                                                id='owner-type'
                                                                value={ownerTypes.find(option => option.value === ownerType)}
                                                                onChange={(e) => setOwnerType(e.value)}
                                                                options={ownerTypes}
                                                                placeholder={'აირჩიეთ საკუთრების ტიპი'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupInput">
                                                            <label htmlFor='land_area'>მიწის ფართი</label>
                                                            <input type='text' onInput={(e) => setBusinessLandArea(e.target.value)} name='land_area' id='land_area' placeholder='ფართი (კვ.მ)' />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupInput">
                                                            <label htmlFor='employee_quantity'>თანამშრომლების რაოდენობა</label>
                                                            <input type='text' onInput={(e) => setBusinessEmployeeQuantity(e.target.value)} name='employee_quantity' id='employee_quantity' placeholder='ფართი (კვ.მ)' />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4} className="mt-3">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setTechnicCar1(e.target.value); } else { setTechnicCar1(null) } }} name="techniccar1" /><span className="customCheckboxBox"></span><strong>მძიმე ტექნიკა</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4} className="mt-3">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setTechnicCar2(e.target.value); } else { setTechnicCar2(null) } }} name="techniccar2" /><span className="customCheckboxBox"></span><strong>სპეც ტექნიკა</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='actives'>აქტივები</label>
                                                            <Select
                                                                id='actives'
                                                                value={actives.find(option => option.value === active)}
                                                                onChange={(e) => setActive(e.value)}
                                                                options={actives}
                                                                placeholder={'აირჩიეთ'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-3 mt-3">
                                                        <Row>
                                                            <Col xxl={3}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setYearRevenue(e.target.value); } else { setYearRevenue(null) } }} name="year_revenue" /><span className="customCheckboxBox"></span><strong>წლიური დარიცხვა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setBusinessRevenueGel(e.target.value)} name='revenue_gel' id='revenue_gel' placeholder='დარიცხვა GEL' />
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setBusinessRevenueUsd(e.target.value)} name='revenue_usd' id='revenue_usd' placeholder='დარიცხვა USD' />
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setBusinessRevenueEur(e.target.value)} name='revenue_eur' id='revenue_eur' placeholder='დარიცხვა EUR' />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mb-3 mt-3">
                                                        <Row>
                                                            <Col xxl={3}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setSumRevenue(e.target.value); } else { setSumRevenue(null) } }} name="sum_revenue" /><span className="customCheckboxBox"></span><strong>წლიური წმინდა მოგება</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setBusinessSumRevenueGel(e.target.value)} name='sum_revenue_gel' id='sum_revenue_gel' placeholder='მოგება GEL' />
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setBusinessSumRevenueUsd(e.target.value)} name='sum_revenue_usd' id='sum_revenue_usd' placeholder='მოგება USD' />
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setBusinessSumRevenueEur(e.target.value)} name='sum_revenue_eur' id='sum_revenue_eur' placeholder='მოგება EUR' />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>


                                                </Row>

                                            </Col>
                                        </Row>

                                    </div>
                                </Col>
                                :
                                ''
                        }



                        {
                            activeType == 'farm' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>ფერმის დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={12}>
                                                <Row>

                                                    <Col xxl={3}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='farm-type'>ფერმის ტიპი</label>
                                                            <Select
                                                                id='farm-type'
                                                                value={farmTypes.find(option => option.value === farmType)}
                                                                onChange={(e) => setFarmType(e.value)}
                                                                options={farmTypes}
                                                                placeholder={'აირჩიეთ ფერმის ტიპი'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={3}>
                                                        <div className="form-group customFormGroupInput">
                                                            <label htmlFor='other-farm-type'>სხვა ფერმის ტიპი</label>
                                                            <input type='text' onInput={(e) => setOtherFarmType(e.target.value)} name='other-farm-type' id='other-farm-type' placeholder='ტიპი' />
                                                        </div>
                                                    </Col>


                                                    <Col xxl={3}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='farm-land-type'>მიწის ტიპი</label>
                                                            <Select
                                                                id='farm-land-type'
                                                                value={farmLandTypes.find(option => option.value === farmLandType)}
                                                                onChange={(e) => setFarmLandType(e.value)}
                                                                options={farmLandTypes}
                                                                placeholder={'აირჩიეთ ტიპი'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={3}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='farm-agricultural-type'>სასოფლოს სამეურნეო მიწის ტიპი</label>
                                                            <Select
                                                                id='farm-agricultural-type'
                                                                value={farmAgriculturalTypes.find(option => option.value === farmAgriculturalType)}
                                                                onChange={(e) => setFarmAgriculturalType(e.value)}
                                                                options={farmAgriculturalTypes}
                                                                placeholder={'აირჩიეთ ტიპი'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-3 mt-3">
                                                        <Row>
                                                            <Col xxl={2}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setFarmBuilding(e.target.value); } else { setFarmBuilding(null) } }} name="farmbuilding" /><span className="customCheckboxBox"></span><strong>შენობა ნაგებობა (ოფისი)</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setOtherFarmBuildingQuantity(e.target.value)} name='farmbuilding_quantity' id='farmbuilding_quantity' placeholder='რაოდენობა' />
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setOtherFarmBuildingArea(e.target.value)} name='farmbuilding_area' id='farmbuilding_area' placeholder='სრული ფართი (კვ.მ)' />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mt-3">
                                                        <Row>
                                                            <Col xxl={12} className="mb-3">
                                                                <div className="subTitle">კომუნიკაციები</div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFarmComunications([...farmComunications, value]); } else { setFarmComunications(farmComunications.filter((item) => item !== value)); } }} name="farmcomunications1" /><span className="customCheckboxBox"></span><strong>გზა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFarmComunications([...farmComunications, value]); } else { setFarmComunications(farmComunications.filter((item) => item !== value)); } }} name="farmcomunications2" /><span className="customCheckboxBox"></span><strong>დენი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFarmComunications([...farmComunications, value]); } else { setFarmComunications(farmComunications.filter((item) => item !== value)); } }} name="farmcomunications3" /><span className="customCheckboxBox"></span><strong>წყალი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFarmComunications([...farmComunications, value]); } else { setFarmComunications(farmComunications.filter((item) => item !== value)); } }} name="farmcomunications4" /><span className="customCheckboxBox"></span><strong>გაზი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFarmComunications([...farmComunications, value]); } else { setFarmComunications(farmComunications.filter((item) => item !== value)); } }} name="farmcomunications5" /><span className="customCheckboxBox"></span><strong>კოლექტორი-კანალიზაცია</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFarmComunications([...farmComunications, value]); } else { setFarmComunications(farmComunications.filter((item) => item !== value)); } }} name="farmcomunications6" /><span className="customCheckboxBox"></span><strong>ჭა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFarmComunications([...farmComunications, value]); } else { setFarmComunications(farmComunications.filter((item) => item !== value)); } }} name="farmcomunications7" /><span className="customCheckboxBox"></span><strong>სერვიტუტი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFarmComunications([...farmComunications, value]); } else { setFarmComunications(farmComunications.filter((item) => item !== value)); } }} name="farmcomunications8" /><span className="customCheckboxBox"></span><strong>პირველი ზოლი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFarmComunications([...farmComunications, value]); } else { setFarmComunications(farmComunications.filter((item) => item !== value)); } }} name="farmcomunications9" /><span className="customCheckboxBox"></span><strong>მეორე ზოლი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFarmComunications([...farmComunications, value]); } else { setFarmComunications(farmComunications.filter((item) => item !== value)); } }} name="farmcomunications10" /><span className="customCheckboxBox"></span><strong>მესამე ზოლი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFarmComunications([...farmComunications, value]); } else { setFarmComunications(farmComunications.filter((item) => item !== value)); } }} name="farmcomunications11" /><span className="customCheckboxBox"></span><strong>ღობე</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFarmComunications([...farmComunications, value]); } else { setFarmComunications(farmComunications.filter((item) => item !== value)); } }} name="farmcomunications12" /><span className="customCheckboxBox"></span><strong>საკუთარი ტრანსფორმატორი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setFarmComunications([...farmComunications, value]); } else { setFarmComunications(farmComunications.filter((item) => item !== value)); } }} name="farmcomunications13" /><span className="customCheckboxBox"></span><strong>საკუთარი გენერატორი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mt-3">
                                                        <Row>
                                                            <Col xxl={12} className="mb-3">
                                                                <div className="subTitle">დამატებითი დეტალები</div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAddonFarmDetails([...addonFarmDetails, value]); } else { setAddonFarmDetails(addonFarmDetails.filter((item) => item !== value)); } }} name="addonfarmdetails1" /><span className="customCheckboxBox"></span><strong>სატუმბი სისტემა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAddonFarmDetails([...addonFarmDetails, value]); } else { setAddonFarmDetails(addonFarmDetails.filter((item) => item !== value)); } }} name="addonfarmdetails2" /><span className="customCheckboxBox"></span><strong>ჭაბურღილი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAddonFarmDetails([...addonFarmDetails, value]); } else { setAddonFarmDetails(addonFarmDetails.filter((item) => item !== value)); } }} name="addonfarmdetails3" /><span className="customCheckboxBox"></span><strong>წვეთოვანი სარწყავი სისტემა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAddonFarmDetails([...addonFarmDetails, value]); } else { setAddonFarmDetails(addonFarmDetails.filter((item) => item !== value)); } }} name="addonfarmdetails4" /><span className="customCheckboxBox"></span><strong>რკინიგზის ჩიხი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { const value = e.target.value; if (e.target.checked) { setAddonFarmDetails([...addonFarmDetails, value]); } else { setAddonFarmDetails(addonFarmDetails.filter((item) => item !== value)); } }} name="addonfarmdetails5" /><span className="customCheckboxBox"></span><strong>კაპიტალური ღობე</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mb-3 mt-4">
                                                        <Row>
                                                            <Col xxl={2}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e) => { if (e.target.checked) { setFarmLake(e.target.value); } else { setFarmLake(null) } }} name="farmlake" /><span className="customCheckboxBox"></span><strong>ტბა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setOtherFarmLakeQuantity(e.target.value)} name='farmlake_quantity' id='farmlake_quantity' placeholder='რაოდენობა' />
                                                                </div>
                                                            </Col>
                                                            <Col xxl={3}>
                                                                <div className="form-group customFormGroupInput">
                                                                    <input type='number' onInput={(e) => setOtherFarmLakeArea(e.target.value)} name='farmlake_area' id='farmlake_area' placeholder='სრული ფართი (კვ.მ)' />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>


                                                </Row>

                                            </Col>
                                        </Row>

                                    </div>
                                </Col>
                                :
                                ''
                        }


                        {
                            activeTransaction == 'installment' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>განვადებით გაყიდვის დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <label htmlFor='price-first'>პირველადი შესატანი თანხა</label>
                                                    <input type='text' onInput={(e) => setInstallmentFirstPrice(e.target.value)} name='price-first' id='price-first' placeholder='თანხა' />
                                                </div>
                                            </Col>

                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <label htmlFor='price-monthly'>ყოველთვიური შესატანი თანხა</label>
                                                    <input type='text' onInput={(e) => setInstallmentMonthlyPrice(e.target.value)} name='price-monthly' id='price-monthly' placeholder='თანხა' />
                                                </div>
                                            </Col>

                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <label htmlFor='price-last'>საბოლოო თანხა ჯამში</label>
                                                    <input type='text' onInput={(e) => setInstallmentLastPrice(e.target.value)} name='price-last' id='price-last' placeholder='თანხა' />
                                                </div>
                                            </Col>

                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <label htmlFor='validity-period'>მოქმედების ვადა</label>
                                                    <input type='text' onInput={(e) => setInstallmentPeriod(e.target.value)} name='validity-period' id='validity-period' placeholder='ჩაწერეთ ვადა' />
                                                </div>
                                            </Col>

                                        </Row>

                                    </div>
                                </Col>
                                :
                                ''
                        }




                        {
                            activeTransaction == 'auction' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>აუქციონით გაყიდვის დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='auction-last-data'>აუქციონის ბოლო ვადა</label>
                                                    <div className="dateBox">
                                                        <DatePicker
                                                            selected={auctionDate}
                                                            onChange={(date) => setAuctionDate(date)}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput">
                                                    <label htmlFor='price-monthly'>ყოველთვიური შესატანი თანხა</label>
                                                    <input type='text' onInput={(e) => setAuctionMonthlyPrice(e.target.value)} name='price-monthly' id='price-monthly' placeholder='თანხა' />
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput">
                                                    <label htmlFor='price-last'>საბოლოო თანხა ჯამში</label>
                                                    <input type='text' onInput={(e) => setAuctionAllPrice(e.target.value)} name='price-last' id='price-last' placeholder='თანხა' />
                                                </div>
                                            </Col>

                                        </Row>

                                    </div>
                                </Col>
                                :
                                ''
                        }



                        {
                            activeTransaction == 'dailyrent' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>დღიურად გაქირავების დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='rent-first-data'>გაქირავების დაწყების დრო</label>
                                                    <div className="dateBox">
                                                        <DatePicker
                                                            selected={rentStartDate}
                                                            onChange={(date) => setRentStartDate(date)}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='rent-last-data'>გაქირავების დასრულების დრო</label>
                                                    <div className="dateBox">
                                                        <DatePicker
                                                            selected={rentEndDate}
                                                            onChange={(date) => setRentEndDate(date)}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>

                                    </div>
                                </Col>
                                :
                                ''
                        }


                        <Col xxl={12} ref={photosFltr}>
                            <div className="cardBox">
                                <div className="cardBoxTitle">
                                    <h4>დაამატეთ ფოტოები</h4>
                                </div>

                                <Row className="cardBoxFormGroup">

                                    {[...Array(15)].map((_, i) => (
                                        <Col key={i} xxl={3} className='mb-3'>
                                            {!images[i] ? (
                                                <div className='uploadMainImageBox' onClick={() => handleUploadClick(i)}>
                                                    <div className='uploadMainImage'>
                                                        <FontAwesomeIcon icon={faPlus} />
                                                        {

                                                            i == 0 ?
                                                                <h4>ქავერის ფოტოს ატვირთვა</h4>
                                                                :
                                                                <h4>ფოტოს ატვირთვა</h4>
                                                        }

                                                    </div>
                                                    <input
                                                        type='file'
                                                        ref={(el) => (uploadAdImageRefs.current[i] = el)} // Assign ref dynamically
                                                        style={{ display: 'none' }}
                                                        accept='.png, .jpg, .jpeg'
                                                        onChange={(e) => handleImageChange(e, i)}
                                                    />
                                                </div>
                                            ) : (
                                                <div className='uploadedImage'>
                                                    <Image src={URL.createObjectURL(images[i])} alt={`Preview ${i + 1}`} />
                                                    <div className='makeCover active'>
                                                        <FontAwesomeIcon icon={faPhotoFilm} title='მთავარ ფოტოდ დაყენება' />
                                                    </div>
                                                    <span title='სურათის წაშლა' onClick={() => handleImageDelete(i)}>
                                                        <FontAwesomeIcon icon={faTrashCan} />
                                                    </span>
                                                </div>
                                            )}
                                        </Col>
                                    ))}





                                    <Col xxl={12}>
                                        <p className='notifyUpload'>* ფოტოების მაქსიმალური რაოდენობაა 15, ფოტოს ზომა არ უნდა აღემატებოდეს 2 მეგაბაიტს!</p>
                                    </Col>

                                </Row>

                            </div>
                        </Col>


                        <Col xxl={12} ref={descriptionFltr}>
                            <div className="cardBox">
                                <div className="cardBoxTitle">
                                    <h4>განცხადების აღწერა</h4>
                                </div>

                                <Row className="cardBoxFormGroup">

                                    <Col xxl={12}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <Tabs
                                                defaultActiveKey="georgian"
                                                id="descriptionLanguage"
                                                className="mainDescTabs"
                                            >
                                                <Tab eventKey="georgian" title="ქართული">
                                                    <textarea onInput={(e) => setDescriptionGeo(e.target.value)} className='w-100 textDescription' rows="6" name='georgianDescription' id='georgianDescription' placeholder='აღწერა ქართულად'></textarea>
                                                </Tab>
                                                <Tab eventKey="english" title="English">
                                                    <textarea onInput={(e) => setDescriptionEng(e.target.value)} className='w-100 textDescription' rows="6" name='englishDescription' id='englishDescription' placeholder='აღწერა ინგლისურად'></textarea>
                                                </Tab>
                                                <Tab eventKey="russian" title="Русский">
                                                    <textarea onInput={(e) => setDescriptionRus(e.target.value)} className='w-100 textDescription' rows="6" name='russianDescription' id='russianDescription' placeholder='აღწერა რუსულად'></textarea>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </Col>

                                </Row>

                            </div>
                        </Col>


                        <Col xxl={12}>
                            <div className="cardBox">

                                <Row className="cardBoxFormGroup">

                                    <Col xxl={12} className='text-center'>
                                        <button type='button' className='addPropertyButton' onClick={(e) => { e.preventDefault(); submitForm(); }}>+ განცხადების დამატება</button>
                                    </Col>

                                </Row>

                            </div>
                        </Col>


                    </Row>
                </Container>
            </section>
        </div>
    )

}


export default Add;