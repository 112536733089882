import React, { useState } from 'react';
import { useAuth } from './../Context';


//import tags
import { Container, Image, Row, Col } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faBars, faPhone, faEnvelope, faHeart, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';

// import components
import Auth from '../dynamic/Auth'
import Sidemenu from '../dynamic/Sidemenu'

//import resources
import logo from '../../assets/image/logo.png'

function Header() {

    const { isLoggedIn, logout } = useAuth();

    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [authModal, setAuthModal] = useState(false);
    const [sideMenuModal, setSideMenuModal] = useState(false);

    const handleMouseEnter = () => {
        setDropdownVisible(true);
    };

    const handleMouseLeave = () => {
        setDropdownVisible(false);
    };

    return (
        <>
            <header>
                <div className='construction'>ვებ გვერდი მზადების პროცესშია!</div>
                <div className='headerTop'>
                    <Container>
                        <Row>
                            <Col xxl={6}>
                                <div className="HeaderTopLeft">
                                    <Link to="tel:+995 123 456 789"><span><FontAwesomeIcon icon={faPhone} /></span> +995 123 456 789</Link>
                                    <Link to="mailto:info@relux.ge"><span><FontAwesomeIcon icon={faEnvelope} /></span> info@relux.ge</Link>
                                </div>
                            </Col>
                            <Col xxl={6}>
                                <div className="HeaderTopRight">
                                    <Link className="saved_box" to="/saved">
                                        <FontAwesomeIcon icon={faHeart} title="შენახული განცხადებები" />
                                        <span>0</span>
                                    </Link>
                                    <Link className="socialTop" to="/">
                                        <FontAwesomeIcon icon={faFacebook} />
                                    </Link>
                                    <Link className="socialTop" to="/">
                                        <FontAwesomeIcon icon={faInstagram} />
                                    </Link>
                                    <Link className="socialTop" to="/">
                                        <FontAwesomeIcon icon={faTiktok} />
                                    </Link>
                                    <Link className="socialTop" to="/">
                                        <FontAwesomeIcon icon={faYoutube} />
                                    </Link>
                                    <Link>&nbsp;</Link>
                                    <Link to="/ka">Ka</Link>
                                    <Link to="/en">En</Link>
                                    <Link to="/ru">Ru</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='header'>
                    <Container>
                        <Row>
                            <Col xxl={2}>
                                <div className='logo'>
                                    <Link to="/">
                                        <Image src={logo} alt="Dropshop" />
                                    </Link>
                                </div>
                            </Col>
                            <Col xxl={4}>
                                <nav className="shortNav">
                                    <ul>
                                        <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <Link to="/property">იყიდება <span><FontAwesomeIcon icon={faChevronDown} /></span></Link>
                                            {isDropdownVisible && (
                                                <ul className="dropdown">
                                                    <div className="dropdownBox">
                                                        <li><Link to="/property">ბინა</Link></li>
                                                        <li><Link to="/property">სახლი</Link></li>
                                                        <li><Link to="/property">აგარაკი</Link></li>
                                                    </div>

                                                </ul>
                                            )}
                                        </li>
                                        <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <Link to="/property">ქირავდება <span><FontAwesomeIcon icon={faChevronDown} /></span></Link>
                                            {isDropdownVisible && (
                                                <ul className="dropdown">
                                                    <div className="dropdownBox">
                                                        <li><Link to="/property">ბინა</Link></li>
                                                        <li><Link to="/property">სახლი</Link></li>
                                                        <li><Link to="/property">აგარაკი</Link></li>
                                                    </div>
                                                </ul>
                                            )}
                                        </li>
                                        <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <Link to="/property">გირავდება <span><FontAwesomeIcon icon={faChevronDown} /></span></Link>
                                            {isDropdownVisible && (
                                                <ul className="dropdown">
                                                    <div className="dropdownBox">
                                                        <li><Link to="/property">ბინა</Link></li>
                                                        <li><Link to="/property">სახლი</Link></li>
                                                        <li><Link to="/property">აგარაკი</Link></li>
                                                    </div>
                                                </ul>
                                            )}
                                        </li>
                                    </ul>
                                </nav>
                            </Col>
                            <Col xxl={6}>
                                <nav className="rightNav">
                                    <Link to="/property/add"><FontAwesomeIcon icon={faPlus} /> განცხადების დამატება</Link>
                                    {isLoggedIn && typeof isLoggedIn == 'object' ? 
                                        <>
                                        <Link className="loginBtn" to='/profile/main'>გამარჯობა: {isLoggedIn?.fname}</Link>
                                        <Link className="loginBtn" onClick={()=>logout()}>გამოსვლა</Link>
                                        </>
                                        :
                                        <Link className="loginBtn" onClick={()=>setAuthModal(true)}>შესვლა / რეგისტრაცია</Link>
                                    }
                                    
                                    <Link className="sideMenu" onClick={()=>setSideMenuModal(true)}><span><FontAwesomeIcon icon={faBars} /></span></Link>
                                </nav>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </header>
            
            <div className={`authModal ` + (authModal ? 'active' : '')}>
                <div className='authModalOuter'>
                    {
                        authModal ? 
                            <>
                                <Auth setAuthModal={setAuthModal} />
                            </>
                        : 
                        ''
                    }
                </div>
            </div>

            <div className={`sideMenuModal ` + (sideMenuModal ? 'active' : '')}>
                <div className='sideMenuModalOuter'>
                    {
                        sideMenuModal ? 
                            <>
                                <Sidemenu setSideMenuModal={setSideMenuModal} />
                            </>
                        : 
                        ''
                    }
                </div>
            </div>
        </>
        
    )

}

export default Header;