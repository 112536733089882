//import tags
import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y } from 'swiper/modules';
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import { Container, Row, Col, Image } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton'


//import resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faLocationCrosshairs, faBed, faDoorOpen, faLayerGroup, faHashtag, faMobileScreen, faChevronRight, faWifi } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular, faClone, faCalendarDays, faFlag } from '@fortawesome/free-regular-svg-icons';

import 'swiper/css';
import 'swiper/css/navigation';

import Propertyimage from '../../assets/image/property.jpg'

//api
// import { get_about } from '../api/Api'

function Main() {

    const [toggleView, setToggleView] = useState('0');
    const [isFavourite, setIsFavourite] = useState(false);
    const [message, setMessage] = useState('');
    const [currentCurrency, setCurrentCurrency] = useState('GEL');
    const [sortBy, setSortBy] = useState('0');
    const [showFullText, setShowFullText] = useState(false);
    const [showFullNumber, setShowFullNumber] = useState(false);

    const longText = "იყიდება გორში ჩეხური ტიპის აგურის ბინა. ბინის სრული ფართი არის 92 კვადრატული მეტრი აივნიანად. აივანზე არის ორი სათავსო. სამი საძინებელი, ერთი სველი წერტილი, გამოყოფილი მისაღები, ზალა და სამზარეულო. აქვს ორი სარდაფი, ორივეს მთლიანი ფართი 25 კვადრატული მეტრი.";

    const { id, slug } = useParams();

    const toggleText = () => {
        setShowFullText(!showFullText);
    };

    const toggleViewHandler = () => {
        setToggleView(!toggleView);
    };

    const toggleFavourite = () => {
        setIsFavourite(prevState => !prevState);
        setMessage(isFavourite ? 'წაიშალა ფავორიტების სიიდან' : 'ფავორიტებში წარმატებით დაემატა');
        setTimeout(() => {
            setMessage('');
        }, 3000);
    };

    const toggleCurrency = (currency) => {
        setCurrentCurrency(currency);
    };

    const toggleShowNumber = () => {
        setShowFullNumber(!showFullNumber);
    };

    return (
        <div className='main'>
            <section className="propertyDetailsSection">
                <Container>
                    <Row>

                        <Col xxl={12}>
                            <div className="shortBreadcumb">
                                <div className="shortBreadcumbLeft">
                                    <h1>ახალი აშენებული ბინა</h1>
                                </div>
                                <div className="shortBreadcumbRight">
                                    <ul>
                                        <li><Link to="/">მთავარი</Link></li>
                                        <li><FontAwesomeIcon icon={faChevronRight} /></li>
                                        <li>განცხადებები</li>
                                        <li><FontAwesomeIcon icon={faChevronRight} /></li>
                                        <li>ახალი აშენებული ბინა</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>

                        {/* {id ?? id}
                        {slug ?? slug} */}

                        <Col xxl={12}>
                            {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                            <div className="mainImageSlider">
                                <Swiper
                                    modules={[Navigation, A11y]}
                                    spaceBetween={5}
                                    slidesPerView={1}
                                    navigation
                                    scrollbar={{ draggable: true }}
                                >
                                    <SwiperSlide>
                                        <div className="singleProductImage">
                                            <Image src={Propertyimage} alt="Property image" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="singleProductImage">
                                            <Image src={Propertyimage} alt="Property image" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="singleProductImage">
                                            <Image src={Propertyimage} alt="Property image" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="singleProductImage">
                                            <Image src={Propertyimage} alt="Property image" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="singleProductImage">
                                            <Image src={Propertyimage} alt="Property image" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="singleProductImage">
                                            <Image src={Propertyimage} alt="Property image" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="singleProductImage">
                                            <Image src={Propertyimage} alt="Property image" />
                                        </div>
                                    </SwiperSlide>
                                </Swiper>

                                <div className="singleProductGridTag">
                                    <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                </div>

                                <div className="singleProductFavourite" onClick={toggleFavourite}>
                                    <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                </div>

                            </div>
                        </Col>

                        <Col xxl={8}>
                            <div className="propertyDetailsLeft">
                                
                                <div className="propertyDetailsTags">
                                    <div className="singleTag">ბინა</div>
                                    <div className="singleTag">თბილისი</div>
                                </div>

                                <h2>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h2>
                                <div className="singlePropertyLocation">
                                    <FontAwesomeIcon icon={faLocationCrosshairs} />
                                    <p>ა.წერეთლის გამზირი #137, 0119</p>
                                </div>

                                <div className="propertyDetailsData">
                                    <p><FontAwesomeIcon icon={faHashtag} /><strong>123456</strong></p>
                                    <p><FontAwesomeIcon icon={faCalendarDays} /><strong>23/04/2024 18:00</strong></p>
                                </div>

                                <div className="singlePropertyMiniBox">
                                    <div className="singlePropertyMini"><FontAwesomeIcon icon={faBed} /> <span>საძინებელი <strong>3</strong></span></div>
                                    <div className="singlePropertyMini"><FontAwesomeIcon icon={faDoorOpen} /> <span>ოთახი <strong>5</strong></span></div>
                                    <div className="singlePropertyMini"><FontAwesomeIcon icon={faLayerGroup} /> <span>სართული <strong>12/14</strong></span></div>
                                    <div className="singlePropertyMini"><FontAwesomeIcon icon={faClone} /> <span>ფართი <strong>120 მ<sup>2</sup></strong></span></div>
                                </div>

                                <div className="propertyDescription">
                                    <h3>აღწერა</h3>
                                    <p>
                                        {showFullText ? longText : longText.slice(0, 142)}
                                        {longText.length > 150 && (
                                        <div className="showMoreText" onClick={toggleText}>
                                            {showFullText ? 'დამალვა' : 'მეტის ნახვა'}
                                        </div>
                                        )}
                                    </p>
                                </div>

                                <div className="propertyDetails">
                                    <h3>დეტალები</h3>

                                    <Row>
                                        <Col xxl={6}>
                                            <div className="propertyDetailsProp">
                                                <h4>სივრცე</h4>

                                                <div className="propertyDetailsPropBox">
                                                    <p><FontAwesomeIcon icon={faClone} /> <span>ახალი გარემონტებული</span></p>
                                                    <p><FontAwesomeIcon icon={faClone} /> <span>ჩეხური</span></p>
                                                    <p><FontAwesomeIcon icon={faClone} /> <span>ჭერის სიმაღლე 2.75 მ</span></p>
                                                    <p><FontAwesomeIcon icon={faClone} /> <span>საძინებელი 3</span></p>
                                                    <p><FontAwesomeIcon icon={faClone} /> <span>აივანი 3 მ²</span></p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={6}>
                                            <div className="propertyDetailsProp">
                                                <h4>კეთილმოწყობა</h4>

                                                <div className="propertyDetailsPropBox">
                                                    <p><FontAwesomeIcon icon={faWifi} /> <span>ცხელი წყალი</span></p>
                                                    <p><FontAwesomeIcon icon={faWifi} /> <span>ბუნებრივი აირი</span></p>
                                                    <p><FontAwesomeIcon icon={faWifi} /> <span>ინტერნეტი</span></p>
                                                    <p><FontAwesomeIcon icon={faWifi} /> <span>ტელეფონი</span></p>
                                                    <p><FontAwesomeIcon icon={faWifi} /> <span>კონდიციონერი</span></p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={6}>
                                            <div className="propertyDetailsProp">
                                                <h4>ობიექტთან ახლოს</h4>

                                                <div className="propertyDetailsPropBox">
                                                    <p><FontAwesomeIcon icon={faFlag} /> <span>მარკეტი</span></p>
                                                    <p><FontAwesomeIcon icon={faFlag} /> <span>საავადმყოფო</span></p>
                                                    <p><FontAwesomeIcon icon={faFlag} /> <span>ტბა</span></p>
                                                    <p><FontAwesomeIcon icon={faFlag} /> <span>მდინარე</span></p>
                                                    <p><FontAwesomeIcon icon={faFlag} /> <span>მეტრო</span></p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                

                            </div>
                        </Col>

                        <Col xxl={4}>
                            <div className="propertyDetailsRight">
                                
                                <div className="singleProductPrice">
                                    <h4>180000 <span >{currentCurrency}</span></h4>
                                    <div className="priceBoxDetails">
                                        <div className={`singlePropertyPrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                            <p>GEL</p>
                                        </div>
                                        <div className={`singlePropertyPrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                            <p>USD</p>
                                        </div>
                                        <div className={`singlePropertyPrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                            <p>EUR</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="oneSquarePrice">
                                    <p>1 მ<sup>2</sup> <span>1.250 {currentCurrency}</span></p>
                                </div>

                                <div className="oneSquareArea">
                                    <p>ფართი: <span>1250 მ<sup>2</sup></span></p>
                                </div>

                                <div className="adAuthor">
                                    <Link to="/">
                                        <div className="adAuthorImg">
                                            <h4>B.N</h4>
                                        </div>
                                    </Link>
                                    <div className="adAuthorText">
                                        <Link to="/"><h5>Bacho Nadiradze</h5></Link>
                                        <p>მესაკუთრე</p>
                                    </div>
                                </div>

                                <div className="adAuthorPhone">
                                    <FontAwesomeIcon icon={faMobileScreen} />
                                    <div className="phoneNumber">
                                        {showFullNumber ? "+995 555 123 456" : "+995 555 123 ***"}
                                    </div>
                                    <span className="showNumber" onClick={toggleShowNumber}>
                                        {showFullNumber ? "ნომრის დამალვა" : "ნომრის ჩვენება"}
                                    </span>
                                </div>

                            </div>
                        </Col>


                        <Col xxl={12}>
                            <div className="relatedAds">
                                <h6>მსგავსი განცხადებები</h6>
                                <Swiper
                                    modules={[Navigation, A11y]}
                                    spaceBetween={5}
                                    slidesPerView={4}
                                    navigation
                                    scrollbar={{ draggable: true }}
                                >
                                    <SwiperSlide>
                                        <div className="singleProductGridBox">
                                            {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                            <div className="singleProductGridBoxImageBox">
                                                <Link to="/property/details/1">
                                                    <Swiper
                                                        modules={[Navigation, A11y]}
                                                        spaceBetween={20}
                                                        slidesPerView={1}
                                                        navigation
                                                        scrollbar={{ draggable: true }}
                                                    >
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>

                                                    </Swiper>
                                                </Link>
                                                <div className="singleProductGridTag">
                                                    <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                </div>
                                                <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                    <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                </div>
                                                <div className="singleProductGridPrice">
                                                    <h4>180000 <span >{currentCurrency}</span></h4>
                                                    <div className="priceBoxGrid">
                                                        <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                            <p>GEL</p>
                                                        </div>
                                                        <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                            <p>USD</p>
                                                        </div>
                                                        <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                            <p>EUR</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="singleProductGridBoxContant">
                                                <div className="propertyGridTags">
                                                    <div className="singleTag">ბინა</div>
                                                    <div className="singleTag">თბილისი</div>
                                                </div>
                                                <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                <div className="singlePropertyGridLocation">
                                                    <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                    <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                </div>
                                                <div className="singlePropertyListMiniBox">
                                                    <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                    <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                    <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                    <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="singleProductGridBox">
                                            {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                            <div className="singleProductGridBoxImageBox">
                                                <Link to="/property/details/1">
                                                    <Swiper
                                                        modules={[Navigation, A11y]}
                                                        spaceBetween={5}
                                                        slidesPerView={1}
                                                        navigation
                                                        scrollbar={{ draggable: true }}
                                                    >
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>

                                                    </Swiper>
                                                </Link>
                                                <div className="singleProductGridTag">
                                                    <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                </div>
                                                <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                    <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                </div>
                                                <div className="singleProductGridPrice">
                                                    <h4>180000 <span >{currentCurrency}</span></h4>
                                                    <div className="priceBoxGrid">
                                                        <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                            <p>GEL</p>
                                                        </div>
                                                        <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                            <p>USD</p>
                                                        </div>
                                                        <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                            <p>EUR</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="singleProductGridBoxContant">
                                                <div className="propertyGridTags">
                                                    <div className="singleTag">ბინა</div>
                                                    <div className="singleTag">თბილისი</div>
                                                </div>
                                                <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                <div className="singlePropertyGridLocation">
                                                    <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                    <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                </div>
                                                <div className="singlePropertyListMiniBox">
                                                    <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                    <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                    <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                    <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="singleProductGridBox">
                                            {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                            <div className="singleProductGridBoxImageBox">
                                                <Link to="/property/details/1">
                                                    <Swiper
                                                        modules={[Navigation, A11y]}
                                                        spaceBetween={5}
                                                        slidesPerView={1}
                                                        navigation
                                                        scrollbar={{ draggable: true }}
                                                    >
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>

                                                    </Swiper>
                                                </Link>
                                                <div className="singleProductGridTag">
                                                    <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                </div>
                                                <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                    <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                </div>
                                                <div className="singleProductGridPrice">
                                                    <h4>180000 <span >{currentCurrency}</span></h4>
                                                    <div className="priceBoxGrid">
                                                        <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                            <p>GEL</p>
                                                        </div>
                                                        <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                            <p>USD</p>
                                                        </div>
                                                        <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                            <p>EUR</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="singleProductGridBoxContant">
                                                <div className="propertyGridTags">
                                                    <div className="singleTag">ბინა</div>
                                                    <div className="singleTag">თბილისი</div>
                                                </div>
                                                <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                <div className="singlePropertyGridLocation">
                                                    <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                    <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                </div>
                                                <div className="singlePropertyListMiniBox">
                                                    <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                    <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                    <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                    <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="singleProductGridBox">
                                            {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                            <div className="singleProductGridBoxImageBox">
                                                <Link to="/property/details/1">
                                                    <Swiper
                                                        modules={[Navigation, A11y]}
                                                        spaceBetween={5}
                                                        slidesPerView={1}
                                                        navigation
                                                        scrollbar={{ draggable: true }}
                                                    >
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>

                                                    </Swiper>
                                                </Link>
                                                <div className="singleProductGridTag">
                                                    <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                </div>
                                                <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                    <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                </div>
                                                <div className="singleProductGridPrice">
                                                    <h4>180000 <span >{currentCurrency}</span></h4>
                                                    <div className="priceBoxGrid">
                                                        <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                            <p>GEL</p>
                                                        </div>
                                                        <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                            <p>USD</p>
                                                        </div>
                                                        <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                            <p>EUR</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="singleProductGridBoxContant">
                                                <div className="propertyGridTags">
                                                    <div className="singleTag">ბინა</div>
                                                    <div className="singleTag">თბილისი</div>
                                                </div>
                                                <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                <div className="singlePropertyGridLocation">
                                                    <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                    <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                </div>
                                                <div className="singlePropertyListMiniBox">
                                                    <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                    <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                    <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                    <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    
                                </Swiper>
                            </div>
                        </Col>


                    </Row>
                </Container>
            </section>
        </div>
    )

}

export default Main;