//import tags
import { Container, Row, Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

// import components
import Slider from '../components/dynamic/Slider'
import District from '../components/dynamic/District'
import Relux from '../components/dynamic/Relux'
import City from '../components/dynamic/City'
import Services from '../components/dynamic/Services'
import Video from '../components/dynamic/Video'
import Map from '../components/dynamic/Map'


function Home() {

    return (
        <div className='main'>
            {/* <Skeleton count={1} height={20} width={150} /> */}
            <Slider/>
            <District/>
            <Relux/>
            <City/>
            <Services/>
            <Map/>
            <Video/>
        </div>
    )
    
}

export default Home;