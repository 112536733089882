// import routes
import {BrowserRouter, Routes, Route} from "react-router-dom";
import HomePage from "./pages/Home";
import AboutPage from "./pages/About";
import CitiesPage from "./pages/Cities";
import VideosPage from "./pages/Videos";
import ContactPage from "./pages/Contact";
import PhotovideoPage from "./pages/Photovideo";
import RatePage from "./pages/Rate";
import ManagementPage from "./pages/Management";
import ExclusivePage from "./pages/Exclusive";
import CheckPage from "./pages/Check";
import InsurancePage from "./pages/Insurance";
import LegalservicePage from "./pages/Legalservice";
import AdsPage from "./pages/Ads";
import CleintsPage from "./pages/Clients";
import RepairservicePage from "./pages/Repairservice";
import MontagePage from "./pages/Montage";
import LandservicePage from "./pages/Landservice";
import BuildingmanagementPage from "./pages/Buildingmanagement";
import ArchitectPage from "./pages/Architect";
import UrbanPage from "./pages/Urban";
import CostservicePage from "./pages/Costservice";
import InteriordesignPage from "./pages/Interiordesign";
import CommunicationsPage from "./pages/Communications";
import ProfilePage from "./pages/profile/Main";
import ProfileinfoPage from "./pages/profile/Ads";
import ProfilefavouritesPage from "./pages/profile/Favourites";
import TermsPage from "./pages/Terms";
import PrivacyPage from "./pages/Privacy";




import NewsPage from "./pages/news/Main";
import NewsDetailPage from "./pages/news/Detail";
import PropertyPage from "./pages/property/Main";
import PropertyDetailPage from "./pages/property/Detail";
import PropertyAddPage from "./pages/property/Add";
import Error404 from "./Error404";

// import components
import Header from './components/static/Header'
import Footer from './components/static/Footer'
import {ContextProvider} from './components/Context'

// import resources
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './assets/css/App.css';

function App() {


    return (
        <div className="App">
            <ContextProvider>
                <BrowserRouter>
                    <Header/>
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="about" element={<AboutPage/>}/>
                        <Route path="cities" element={<CitiesPage/>}/>
                        <Route path="videos" element={<VideosPage/>}/>
                        <Route path="contact" element={<ContactPage/>}/>
                        <Route path="photovideo" element={<PhotovideoPage/>}/>
                        <Route path="rate" element={<RatePage/>}/>
                        <Route path="management" element={<ManagementPage/>}/>
                        <Route path="exclusive" element={<ExclusivePage/>}/>
                        <Route path="check" element={<CheckPage/>}/>
                        <Route path="insurance" element={<InsurancePage/>}/>
                        <Route path="legalservice" element={<LegalservicePage/>}/>
                        <Route path="ads" element={<AdsPage/>}/>
                        <Route path="clients" element={<CleintsPage/>}/>
                        <Route path="repairservice" element={<RepairservicePage/>}/>
                        <Route path="montage" element={<MontagePage/>}/>
                        <Route path="landservice" element={<LandservicePage/>}/>
                        <Route path="buildingmanagement" element={<BuildingmanagementPage/>}/>
                        <Route path="architect" element={<ArchitectPage/>}/>
                        <Route path="urban" element={<UrbanPage/>}/>
                        <Route path="costservice" element={<CostservicePage/>}/>
                        <Route path="interiordesign" element={<InteriordesignPage/>}/>
                        <Route path="communications" element={<CommunicationsPage/>}/>
                        <Route path="/profile/main" element={<ProfilePage/>}/>
                        <Route path="/profile/ads" element={<ProfileinfoPage/>}/>
                        <Route path="/profile/favourites" element={<ProfilefavouritesPage/>}/>
                        <Route path="/terms" element={<TermsPage/>}/>
                        <Route path="/privacy" element={<PrivacyPage/>}/>
                        
                        
                        {/*News*/}
                        <Route path="newses" element={<NewsPage />}/>
                        <Route path="news/:id/:slug" element={<NewsDetailPage />} />


                        {/*Property*/}
                        <Route path="property" element={<PropertyPage />} />
                        <Route path="property/:id/:slug" element={<PropertyDetailPage />} />
                        <Route path="property/add" element={<PropertyAddPage />} />
                        
                        {/* Route for 404 Not Found */}
                        <Route path="*" element={<Error404 />} />
                    </Routes>
                    <Footer/>
                </BrowserRouter>
            </ContextProvider>

        </div>
    );
}

export default App;
