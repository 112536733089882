//import tags
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faHeart, faLocationCrosshairs, faBed, faDoorOpen, faLayerGroup, faHashtag, faMobileScreen, faWifi } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular, faClone, faCalendarDays, faFlag } from '@fortawesome/free-regular-svg-icons';

import 'swiper/css';
import 'swiper/css/navigation';

// import recources
import Propertyimage from '../../assets/image/property.jpg'
import ReluxSectionLogo from '../../assets/image/favicon.png'


function Relux() {

    const [message, setMessage] = useState('');
    const [isFavourite, setIsFavourite] = useState(false);
    const [currentCurrency, setCurrentCurrency] = useState('GEL');

    const toggleFavourite = () => {
        setIsFavourite(prevState => !prevState);
        setMessage(isFavourite ? 'წაიშალა ფავორიტების სიიდან' : 'ფავორიტებში წარმატებით დაემატა');
        setTimeout(() => {
            setMessage('');
        }, 3000);
    };

    const toggleCurrency = (currency) => {
        setCurrentCurrency(currency);
    };

    return (
        <Container>
            <Row>
                <Col xxl={12}>
                    <section className="reluxSection">
                        
                        <div className="sectionHeader">
                            <div className="sectionHeaderLeft">
                                <div className="sectionTitleLogo">
                                    <div className="sectionTitleLogoLeft">
                                        <Image src={ReluxSectionLogo} alt="Relux" />
                                    </div>
                                    <div className="sectionTitleLogoRight">
                                        <h2>Relux განცხადებები</h2>
                                        <p>მოიძიეთ RELUX განცხადებები.</p>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="sectionHeaderRight">
                                <Link to="/">ყველას ნახვა <FontAwesomeIcon icon={faChevronRight} /></Link>
                            </div>
                        </div>

                        {message && <div className="addedToFafourites"><h4>{message}</h4></div>}

                        <div className="citySlider">
                            <Swiper
                                // install Swiper modules
                                modules={[Navigation, A11y]}
                                spaceBetween={20}
                                slidesPerView={4}
                                navigation
                            >
                                <SwiperSlide>
                                    <div className="singleProductGridBox">
                                        <div className="singleProductGridBoxImageBox">
                                            <Link to="/property/details/1">
                                                <Swiper
                                                    modules={[Navigation, A11y]}
                                                    spaceBetween={5}
                                                    slidesPerView={1}
                                                    navigation
                                                    scrollbar={{ draggable: true }}
                                                >
                                                    <SwiperSlide>
                                                        <div className="singleProductGridBoxImage">
                                                            <Image src={Propertyimage} alt="Property image" />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className="singleProductGridBoxImage">
                                                            <Image src={Propertyimage} alt="Property image" />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className="singleProductGridBoxImage">
                                                            <Image src={Propertyimage} alt="Property image" />
                                                        </div>
                                                    </SwiperSlide>

                                                </Swiper>
                                            </Link>
                                            <div className="singleProductGridTagRelux">
                                                <div className="reluxAd">
                                                    <Link to="/"><h4>RELUX</h4></Link>
                                                </div>
                                                <div className="exclusiveAd">
                                                    <Link to="/"><h4>Exclusive</h4></Link>
                                                </div>
                                            </div>
                                            <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                            </div>
                                            <div className="singleProductGridPrice">
                                                <h4>180000 <span >{currentCurrency}</span></h4>
                                                <div className="priceBoxGrid">
                                                    <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                        <p>GEL</p>
                                                    </div>
                                                    <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                        <p>USD</p>
                                                    </div>
                                                    <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                        <p>EUR</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="singleProductGridBoxContant">
                                            <div className="propertyGridTags">
                                                <div className="singleTag">კერძო სახლი</div>
                                                <div className="singleTag">საბურთალო</div>
                                                <div className="singleTag">თბილისი</div>
                                            </div>
                                            <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                            <div className="singlePropertyGridLocation">
                                                <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                <p>ა.წერეთლის გამზირი #137, 0119</p>
                                            </div>
                                            <div className="singlePropertyListMiniBox">
                                                <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="singleProductGridBox">
                                        <div className="singleProductGridBoxImageBox">
                                            <Link to="/property/details/1">
                                                <Swiper
                                                    modules={[Navigation, A11y]}
                                                    spaceBetween={5}
                                                    slidesPerView={1}
                                                    navigation
                                                    scrollbar={{ draggable: true }}
                                                >
                                                    <SwiperSlide>
                                                        <div className="singleProductGridBoxImage">
                                                            <Image src={Propertyimage} alt="Property image" />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className="singleProductGridBoxImage">
                                                            <Image src={Propertyimage} alt="Property image" />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className="singleProductGridBoxImage">
                                                            <Image src={Propertyimage} alt="Property image" />
                                                        </div>
                                                    </SwiperSlide>

                                                </Swiper>
                                            </Link>
                                            <div className="singleProductGridTagRelux">
                                                <div className="reluxAd">
                                                    <Link to="/"><h4>RELUX</h4></Link>
                                                </div>
                                                <div className="exclusiveAd">
                                                    <Link to="/"><h4>Exclusive</h4></Link>
                                                </div>
                                            </div>
                                            <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                            </div>
                                            <div className="singleProductGridPrice">
                                                <h4>180000 <span >{currentCurrency}</span></h4>
                                                <div className="priceBoxGrid">
                                                    <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                        <p>GEL</p>
                                                    </div>
                                                    <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                        <p>USD</p>
                                                    </div>
                                                    <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                        <p>EUR</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="singleProductGridBoxContant">
                                            <div className="propertyGridTags">
                                                <div className="singleTag">კერძო სახლი</div>
                                                <div className="singleTag">საბურთალო</div>
                                                <div className="singleTag">თბილისი</div>
                                            </div>
                                            <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                            <div className="singlePropertyGridLocation">
                                                <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                <p>ა.წერეთლის გამზირი #137, 0119</p>
                                            </div>
                                            <div className="singlePropertyListMiniBox">
                                                <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="singleProductGridBox">
                                        <div className="singleProductGridBoxImageBox">
                                            <Link to="/property/details/1">
                                                <Swiper
                                                    modules={[Navigation, A11y]}
                                                    spaceBetween={5}
                                                    slidesPerView={1}
                                                    navigation
                                                    scrollbar={{ draggable: true }}
                                                >
                                                    <SwiperSlide>
                                                        <div className="singleProductGridBoxImage">
                                                            <Image src={Propertyimage} alt="Property image" />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className="singleProductGridBoxImage">
                                                            <Image src={Propertyimage} alt="Property image" />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className="singleProductGridBoxImage">
                                                            <Image src={Propertyimage} alt="Property image" />
                                                        </div>
                                                    </SwiperSlide>

                                                </Swiper>
                                            </Link>
                                            <div className="singleProductGridTagRelux">
                                                <div className="reluxAd">
                                                    <Link to="/"><h4>RELUX</h4></Link>
                                                </div>
                                                <div className="exclusiveAd">
                                                    <Link to="/"><h4>Exclusive</h4></Link>
                                                </div>
                                            </div>
                                            <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                            </div>
                                            <div className="singleProductGridPrice">
                                                <h4>180000 <span >{currentCurrency}</span></h4>
                                                <div className="priceBoxGrid">
                                                    <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                        <p>GEL</p>
                                                    </div>
                                                    <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                        <p>USD</p>
                                                    </div>
                                                    <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                        <p>EUR</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="singleProductGridBoxContant">
                                            <div className="propertyGridTags">
                                                <div className="singleTag">კერძო სახლი</div>
                                                <div className="singleTag">საბურთალო</div>
                                                <div className="singleTag">თბილისი</div>
                                            </div>
                                            <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                            <div className="singlePropertyGridLocation">
                                                <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                <p>ა.წერეთლის გამზირი #137, 0119</p>
                                            </div>
                                            <div className="singlePropertyListMiniBox">
                                                <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="singleProductGridBox">
                                        <div className="singleProductGridBoxImageBox">
                                            <Link to="/property/details/1">
                                                <Swiper
                                                    modules={[Navigation, A11y]}
                                                    spaceBetween={5}
                                                    slidesPerView={1}
                                                    navigation
                                                    scrollbar={{ draggable: true }}
                                                >
                                                    <SwiperSlide>
                                                        <div className="singleProductGridBoxImage">
                                                            <Image src={Propertyimage} alt="Property image" />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className="singleProductGridBoxImage">
                                                            <Image src={Propertyimage} alt="Property image" />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className="singleProductGridBoxImage">
                                                            <Image src={Propertyimage} alt="Property image" />
                                                        </div>
                                                    </SwiperSlide>

                                                </Swiper>
                                            </Link>
                                            <div className="singleProductGridTagRelux">
                                                <div className="reluxAd">
                                                    <Link to="/"><h4>RELUX</h4></Link>
                                                </div>
                                                <div className="exclusiveAd">
                                                    <Link to="/"><h4>Exclusive</h4></Link>
                                                </div>
                                            </div>
                                            <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                            </div>
                                            <div className="singleProductGridPrice">
                                                <h4>180000 <span >{currentCurrency}</span></h4>
                                                <div className="priceBoxGrid">
                                                    <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                        <p>GEL</p>
                                                    </div>
                                                    <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                        <p>USD</p>
                                                    </div>
                                                    <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                        <p>EUR</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="singleProductGridBoxContant">
                                            <div className="propertyGridTags">
                                                <div className="singleTag">კერძო სახლი</div>
                                                <div className="singleTag">საბურთალო</div>
                                                <div className="singleTag">თბილისი</div>
                                            </div>
                                            <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                            <div className="singlePropertyGridLocation">
                                                <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                <p>ა.წერეთლის გამზირი #137, 0119</p>
                                            </div>
                                            <div className="singlePropertyListMiniBox">
                                                <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="singleProductGridBox">
                                        <div className="singleProductGridBoxImageBox">
                                            <Link to="/property/details/1">
                                                <Swiper
                                                    modules={[Navigation, A11y]}
                                                    spaceBetween={5}
                                                    slidesPerView={1}
                                                    navigation
                                                    scrollbar={{ draggable: true }}
                                                >
                                                    <SwiperSlide>
                                                        <div className="singleProductGridBoxImage">
                                                            <Image src={Propertyimage} alt="Property image" />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className="singleProductGridBoxImage">
                                                            <Image src={Propertyimage} alt="Property image" />
                                                        </div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div className="singleProductGridBoxImage">
                                                            <Image src={Propertyimage} alt="Property image" />
                                                        </div>
                                                    </SwiperSlide>

                                                </Swiper>
                                            </Link>
                                            <div className="singleProductGridTagRelux">
                                                <div className="reluxAd">
                                                    <Link to="/"><h4>RELUX</h4></Link>
                                                </div>
                                                <div className="exclusiveAd">
                                                    <Link to="/"><h4>Exclusive</h4></Link>
                                                </div>
                                            </div>
                                            <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                            </div>
                                            <div className="singleProductGridPrice">
                                                <h4>180000 <span >{currentCurrency}</span></h4>
                                                <div className="priceBoxGrid">
                                                    <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                        <p>GEL</p>
                                                    </div>
                                                    <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                        <p>USD</p>
                                                    </div>
                                                    <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                        <p>EUR</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="singleProductGridBoxContant">
                                            <div className="propertyGridTags">
                                                <div className="singleTag">კერძო სახლი</div>
                                                <div className="singleTag">საბურთალო</div>
                                                <div className="singleTag">თბილისი</div>
                                            </div>
                                            <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                            <div className="singlePropertyGridLocation">
                                                <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                <p>ა.წერეთლის გამზირი #137, 0119</p>
                                            </div>
                                            <div className="singlePropertyListMiniBox">
                                                <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                
                            </Swiper>
                        </div>

                    </section>
                </Col>
            </Row>
        </Container>
    )

}

export default Relux;