//import tags
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton'
import parse from 'html-react-parser';

//import resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

//api
import { get_info } from '../api/Api'


function Landservice() {

    const baseUrl = 'https://reluxapi.myweb.com.ge';
    const [info, setInfo] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
          try {
            get_info(setInfo, 12);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        
        if(!info) {
            fetchData();
        }
    
    }, [info]);

    return (
        <div className='main'>

            <section className="breadcumbSection" id="sectionBreadCumb">
                <Container>
                    <Row>
                        <Col xxl={12}>
                            <div className="breadcumb">
                                <div className="breadcumbLeft">
                                    <h1>მიწის სამუშაოები</h1>
                                </div>
                                <div className="breadcumbRight">
                                    <ul>
                                        <li><Link to="/">მთავარი</Link></li>
                                        <li><FontAwesomeIcon icon={faChevronRight} /></li>
                                        <li>მიწის სამუშაოები</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="contactSection">
                <Container>
                    <Row>

                        <Col xxl={6}>
                            <div className="infoPageLeft">
                                <div className="infoPageTitle">
                                    <h2>მიწის სამუშაოები</h2>
                                </div>

                                {typeof info === 'object' ? parse(info.translations[0].text) : <Skeleton count={10} />}

                                <div className="infopageButtonBox">
                                    <Link to="/contact" className="infopageButton">დაგვიკავშირდით</Link>
                                </div>
                            </div>
                        </Col>

                        <Col xxl={6}>
                            <div className="contactRight">
                                <div className="infoPageImageBox">
                                    {typeof info === 'object' ? (
                                        <Image src={baseUrl+'/storage/info/thumbnail/'+info.image} alt="მიწის სამუშაოები" />
                                    ) : (
                                        <Skeleton style={{ width: '100%', height: '100%' }} />
                                    )}
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
        </div>
    )

}

export default Landservice;