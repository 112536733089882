import React, { createContext, useContext, useEffect, useState } from 'react';

//api
import { get_user } from '../api/Api'


const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const ContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [updatedUser, setUpdatedUser] = useState(false);

  useEffect(() => {
    
    if (sessionStorage.hasOwnProperty('token')) {
      const fetchData = async () => {
        
        const header = {
          'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }

        try {
          get_user(setIsLoggedIn, header);
  
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };


      if (isLoggedIn == null || isLoggedIn == true || updatedUser) {
        fetchData();
        setUpdatedUser(false);
      }
      
    } else {
      setIsLoggedIn(false);
    }

  }, [isLoggedIn, updatedUser]);

  const login = () => {
    setIsLoggedIn(true);
  };

  const updateUserContext = () => {
    setUpdatedUser(true);
  };

  const logout = () => {

    setIsLoggedIn(false);
    
    if(sessionStorage.hasOwnProperty('token')) {
      sessionStorage.removeItem('token');
    }
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, updatedUser, updateUserContext, logout }}>
      {children}
    </AuthContext.Provider>
  );
};