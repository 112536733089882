//import tags
import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y } from 'swiper/modules';
import { Link } from "react-router-dom";
import axios from 'axios';
import { Container, Row, Col, Image } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton'


//import resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faTableCellsLarge, faHeart, faLocationCrosshairs, faBed, faDoorOpen, faLayerGroup, faChevronDown, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular, faClone } from '@fortawesome/free-regular-svg-icons';

import 'swiper/css';
import 'swiper/css/navigation';

import Propertyimage from '../../assets/image/property.jpg'

//api
// import { get_about } from '../api/Api'

function Main() {

    const [toggleView, setToggleView] = useState('0');
    const [isFavourite, setIsFavourite] = useState(false);
    const [message, setMessage] = useState('');
    const [currentCurrency, setCurrentCurrency] = useState('GEL');
    const [sortBy, setSortBy] = useState('0');
    const [isVisibleFilter, setIsVisibleFilter] = useState(false);

    const toggleViewHandler = () => {
        setToggleView(!toggleView);
    };

    const toggleFavourite = () => {
        setIsFavourite(prevState => !prevState);
        setMessage(isFavourite ? 'წაიშალა ფავორიტების სიიდან' : 'ფავორიტებში წარმატებით დაემატა');
        setTimeout(() => {
            setMessage('');
        }, 3000);
    };

    const toggleCurrency = (currency) => {
        setCurrentCurrency(currency);
    };

    const toggleVisibility = () => {
        setIsVisibleFilter(!isVisibleFilter);
    };

    return (
        <div className='main'>
            <section className="productSection">
                <Container>
                    <Row>

                        <Col xxl={12}>
                            <div className="productCard">
                                <div className="filterInside">
                                    <div className="singleFilterBox">
                                        
                                        <div className='filterBoxMain'>
                                            awd
                                        </div>
                                        
                                        <div className='singleFilterBoxShow' onClick={toggleVisibility}>
                                            {isVisibleFilter ?
                                                'show less'
                                                :
                                                'show more'
                                            }
                                        </div>
                                        {isVisibleFilter && (
                                            <div className='singleFilterBoxHidden'>
                                                awd
                                            </div>
                                        )}

                                    </div>
                                </div>

                            </div>
                        </Col>

                        <Col xxl={12}>
                            <div className="productCard">
                                <div className="productCardTopSortBox">
                                    <div className="productCardTopSortBoxLeft">
                                        <h4>მოიძებნა <span>245</span> განცხადება</h4>
                                    </div>
                                    <div className="productCardTopSortBoxRight">

                                        <div className="form-group sortBYButton">
                                            <select value={sortBy} onChange={(event) => setSortBy(event.target.value)} className="form-control">
                                                <option value="0">დალაგება</option>
                                                <option value="1">ფასი ზრდადობით</option>
                                                <option value="2">ფასი კლებადობით</option>
                                                <option value="1">თარიღი ზრდადობით</option>
                                                <option value="2">თარიღი კლებადობით</option>
                                            </select>
                                            <FontAwesomeIcon icon={faChevronDown} />
                                        </div>
                                        
                                        <div className="changeViewButton" onClick={toggleViewHandler}>
                                            {toggleView ? <FontAwesomeIcon title="Grid view" icon={faTableCellsLarge} /> : <FontAwesomeIcon title="List view" icon={faList} />}
                                        </div>

                                    </div>
                                </div>
                                <Row>
                                    
                                    <div className={toggleView ? "gridView col-xxl-3" : "listView col-xxl-12"}>

                                        {toggleView ? (
                                            <div className="singleProductGridBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductGridBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductGridTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                    </div>
                                                    <div className="singleProductGridPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxGrid">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="singleProductGridBoxContant">
                                                    <div className="propertyGridTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                    <div className="singlePropertyGridLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="singleProductListBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductListBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductListTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    
                                                    
                                                </div>
                                                <div className="singleProductListFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                </div>
                                                <div className="singleProductListBoxContant">
                                                    <div className="propertyListTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>

                                                    <div className="singleProductListPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxList">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="singlePropertyListLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyListMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyListMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyListMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyListMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>

                                    <div className={toggleView ? "gridView col-xxl-3" : "listView col-xxl-12"}>

                                        {toggleView ? (
                                            <div className="singleProductGridBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductGridBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                    </div>
                                                    <div className="singleProductGridPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxGrid">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="singleProductGridBoxContant">
                                                    <div className="propertyGridTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                    <div className="singlePropertyGridLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="singleProductListBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductListBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    
                                                </div>
                                                <div className="singleProductListFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                </div>
                                                <div className="singleProductListBoxContant">
                                                    <div className="propertyListTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>

                                                    <div className="singleProductListPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxList">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="singlePropertyListLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyListMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyListMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyListMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyListMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>

                                    <div className={toggleView ? "gridView col-xxl-3" : "listView col-xxl-12"}>

                                        {toggleView ? (
                                            <div className="singleProductGridBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductGridBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductGridTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                    </div>
                                                    <div className="singleProductGridPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxGrid">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="singleProductGridBoxContant">
                                                    <div className="propertyGridTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                    <div className="singlePropertyGridLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="singleProductListBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductListBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductListTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    
                                                    
                                                </div>
                                                <div className="singleProductListFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                </div>
                                                <div className="singleProductListBoxContant">
                                                    <div className="propertyListTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>

                                                    <div className="singleProductListPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxList">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="singlePropertyListLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyListMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyListMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyListMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyListMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>

                                    <div className={toggleView ? "gridView col-xxl-3" : "listView col-xxl-12"}>

                                        {toggleView ? (
                                            <div className="singleProductGridBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductGridBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductGridTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                    </div>
                                                    <div className="singleProductGridPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxGrid">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="singleProductGridBoxContant">
                                                    <div className="propertyGridTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                    <div className="singlePropertyGridLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="singleProductListBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductListBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductListTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    
                                                    
                                                </div>
                                                <div className="singleProductListFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                </div>
                                                <div className="singleProductListBoxContant">
                                                    <div className="propertyListTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>

                                                    <div className="singleProductListPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxList">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="singlePropertyListLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyListMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyListMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyListMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyListMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>

                                    <div className={toggleView ? "gridView col-xxl-3" : "listView col-xxl-12"}>

                                        {toggleView ? (
                                            <div className="singleProductGridBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductGridBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductGridTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                    </div>
                                                    <div className="singleProductGridPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxGrid">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="singleProductGridBoxContant">
                                                    <div className="propertyGridTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                    <div className="singlePropertyGridLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="singleProductListBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductListBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductListTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    
                                                    
                                                </div>
                                                <div className="singleProductListFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                </div>
                                                <div className="singleProductListBoxContant">
                                                    <div className="propertyListTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>

                                                    <div className="singleProductListPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxList">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="singlePropertyListLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyListMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyListMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyListMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyListMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>

                                    <div className={toggleView ? "gridView col-xxl-3" : "listView col-xxl-12"}>

                                        {toggleView ? (
                                            <div className="singleProductGridBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductGridBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductGridTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                    </div>
                                                    <div className="singleProductGridPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxGrid">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="singleProductGridBoxContant">
                                                    <div className="propertyGridTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                    <div className="singlePropertyGridLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="singleProductListBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductListBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductListTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    
                                                    
                                                </div>
                                                <div className="singleProductListFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                </div>
                                                <div className="singleProductListBoxContant">
                                                    <div className="propertyListTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>

                                                    <div className="singleProductListPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxList">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="singlePropertyListLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyListMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyListMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyListMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyListMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>

                                    <div className={toggleView ? "gridView col-xxl-3" : "listView col-xxl-12"}>

                                        {toggleView ? (
                                            <div className="singleProductGridBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductGridBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductGridTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                    </div>
                                                    <div className="singleProductGridPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxGrid">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="singleProductGridBoxContant">
                                                    <div className="propertyGridTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                    <div className="singlePropertyGridLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="singleProductListBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductListBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductListTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    
                                                    
                                                </div>
                                                <div className="singleProductListFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                </div>
                                                <div className="singleProductListBoxContant">
                                                    <div className="propertyListTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>

                                                    <div className="singleProductListPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxList">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="singlePropertyListLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyListMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyListMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyListMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyListMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                    
                                    <div className={toggleView ? "gridView col-xxl-3" : "listView col-xxl-12"}>

                                        {toggleView ? (
                                            <div className="singleProductGridBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductGridBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductGridTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                    </div>
                                                    <div className="singleProductGridPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxGrid">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="singleProductGridBoxContant">
                                                    <div className="propertyGridTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                    <div className="singlePropertyGridLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="singleProductListBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductListBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductListTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    
                                                    
                                                </div>
                                                <div className="singleProductListFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                </div>
                                                <div className="singleProductListBoxContant">
                                                    <div className="propertyListTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>

                                                    <div className="singleProductListPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxList">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="singlePropertyListLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyListMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyListMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyListMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyListMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>

                                    <div className={toggleView ? "gridView col-xxl-3" : "listView col-xxl-12"}>

                                        {toggleView ? (
                                            <div className="singleProductGridBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductGridBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductGridTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                    </div>
                                                    <div className="singleProductGridPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxGrid">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="singleProductGridBoxContant">
                                                    <div className="propertyGridTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                    <div className="singlePropertyGridLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="singleProductListBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductListBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductListTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    
                                                    
                                                </div>
                                                <div className="singleProductListFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                </div>
                                                <div className="singleProductListBoxContant">
                                                    <div className="propertyListTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>

                                                    <div className="singleProductListPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxList">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="singlePropertyListLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyListMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyListMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyListMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyListMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>

                                    <div className={toggleView ? "gridView col-xxl-3" : "listView col-xxl-12"}>

                                        {toggleView ? (
                                            <div className="singleProductGridBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductGridBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductGridTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                    </div>
                                                    <div className="singleProductGridPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxGrid">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="singleProductGridBoxContant">
                                                    <div className="propertyGridTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                    <div className="singlePropertyGridLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="singleProductListBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductListBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductListTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    
                                                    
                                                </div>
                                                <div className="singleProductListFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                </div>
                                                <div className="singleProductListBoxContant">
                                                    <div className="propertyListTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>

                                                    <div className="singleProductListPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxList">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="singlePropertyListLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyListMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyListMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyListMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyListMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>

                                    <div className={toggleView ? "gridView col-xxl-3" : "listView col-xxl-12"}>

                                        {toggleView ? (
                                            <div className="singleProductGridBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductGridBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductGridTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                    </div>
                                                    <div className="singleProductGridPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxGrid">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="singleProductGridBoxContant">
                                                    <div className="propertyGridTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                    <div className="singlePropertyGridLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="singleProductListBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductListBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductListTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    
                                                    
                                                </div>
                                                <div className="singleProductListFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                </div>
                                                <div className="singleProductListBoxContant">
                                                    <div className="propertyListTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>

                                                    <div className="singleProductListPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxList">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="singlePropertyListLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyListMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyListMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyListMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyListMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>

                                    <div className={toggleView ? "gridView col-xxl-3" : "listView col-xxl-12"}>

                                        {toggleView ? (
                                            <div className="singleProductGridBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductGridBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductGridBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductGridTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                    </div>
                                                    <div className="singleProductGridPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxGrid">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="singleProductGridBoxContant">
                                                    <div className="propertyGridTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                    <div className="singlePropertyGridLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="singleProductListBox">
                                                {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                                <div className="singleProductListBoxImageBox">
                                                    <Link to="/property/details/1">
                                                        <Swiper
                                                            modules={[Navigation, A11y]}
                                                            spaceBetween={5}
                                                            slidesPerView={1}
                                                            navigation
                                                            scrollbar={{ draggable: true }}
                                                        >
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>
                                                            <SwiperSlide>
                                                                <div className="singleProductListBoxImage">
                                                                    <Image src={Propertyimage} alt="Property image" />
                                                                </div>
                                                            </SwiperSlide>

                                                        </Swiper>
                                                    </Link>
                                                    <div className="singleProductListTag">
                                                        <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                    </div>
                                                    
                                                    
                                                </div>
                                                <div className="singleProductListFavourite" onClick={toggleFavourite}>
                                                        <FontAwesomeIcon icon={isFavourite ? faHeart : faHeartRegular} />
                                                </div>
                                                <div className="singleProductListBoxContant">
                                                    <div className="propertyListTags">
                                                        <div className="singleTag">ბინა</div>
                                                        <div className="singleTag">თბილისი</div>
                                                    </div>
                                                    <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>

                                                    <div className="singleProductListPrice">
                                                        <h4>180000 <span >{currentCurrency}</span></h4>
                                                        <div className="priceBoxList">
                                                            <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                                <p>GEL</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                                <p>USD</p>
                                                            </div>
                                                            <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                                <p>EUR</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="singlePropertyListLocation">
                                                        <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                        <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                    </div>
                                                    <div className="singlePropertyListMiniBox">
                                                        <div className="singlePropertyListMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                        <div className="singlePropertyListMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                        <div className="singlePropertyListMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                        <div className="singlePropertyListMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>

                                    
                                    <div className="col-xxl-12 mt-5">
                                        <div className="pagination">
                                            <button className="pagination-button paginatePrev">
                                                <FontAwesomeIcon icon={faArrowLeft} />
                                            </button>
                                            <div className="pagination-pages paginateMiddle">
                                                <div className="pagination-page paginateSingle active">1</div>
                                                <div className="pagination-page paginateSingle">2</div>
                                                <div className="pagination-page paginateSingle">3</div>
                                                <div className="pagination-page paginateSingle">4</div>
                                                <div className="pagination-page paginateSingle">5</div>
                                            </div>
                                            <button className="pagination-button paginateNext">
                                                <FontAwesomeIcon icon={faArrowRight} />
                                            </button>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
        </div>
    )

}

export default Main;