import {useState, useEffect} from 'react'

//import tags
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';

// import recources
import Facebook from '../../assets/image/facebook.png'
import Youtube from '../../assets/image/youtube.png'
import Location from '../../assets/image/location.png'
import Envelope from '../../assets/image/envelope.png'
import Phone from '../../assets/image/phone.png'

//api
import { get_contact } from '../../api/Api'

function getDate() {
    const today = new Date();
    const year = today.getFullYear();
    return `${year}`;
}


  
function Footer() {

    const [currentDate, setCurrentDate] = useState(getDate());

    const baseUrl = 'https://reluxapi.myweb.com.ge';
    const [contact, setContact] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
          try {
            get_contact(setContact);

          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };

        if(!contact) {
            fetchData();
        }

      }, [contact]);

    return (
        <footer className="footer">
            <Container>
                <Row>
                    <Col xxl={3}>
                        <div className="footer_menu">
                            <h4>განცხადებები</h4>
                            <ul>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> ბინები თბილისში</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> ახალაშენებული ბინები</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> ბინები ბათუმში</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> იყიდება 1 ოთახიანი ბინა</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> იყიდება კერძო სახლი</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> მშენებარე ბინები თბილისში</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> მშენებარე ბინები ბათუმში</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> იყიდება კერძო სახლები</Link></li>
                            </ul>
                        </div>
                    </Col>
                    <Col xxl={3}>
                        <div className="footer_menu">
                            <h4>&nbsp;</h4>
                            <ul>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> ბინები თბილისში</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> ახალაშენებული ბინები</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> ბინები ბათუმში</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> იყიდება 1 ოთახიანი ბინა</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> იყიდება კერძო სახლი</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> მშენებარე ბინები თბილისში</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> მშენებარე ბინები ბათუმში</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> იყიდება კერძო სახლები</Link></li>
                            </ul>
                        </div>
                    </Col>
                    <Col xxl={3}>
                        <div className="footer_menu">
                            <h4>&nbsp;</h4>
                            <ul>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> ბინები თბილისში</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> ახალაშენებული ბინები</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> ბინები ბათუმში</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> იყიდება 1 ოთახიანი ბინა</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> იყიდება კერძო სახლი</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> მშენებარე ბინები თბილისში</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> მშენებარე ბინები ბათუმში</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faCaretRight} /> იყიდება კერძო სახლები</Link></li>
                            </ul>
                        </div>
                    </Col>
                    <Col xxl={3}>
                        <div className="footer_menu">
                            <h4>საკონტაქტო ინფო</h4>
                            <ul className="footerContactIcons">
                                <li><Link to="/"><Image src={Location} alt="Location" />{ typeof contact == 'object' ? contact?.translations[0].address : '' }</Link></li>
                                <li><Link to={ typeof contact == 'object' ? 'mailto:'+contact?.email : '' }><Image src={Envelope} alt="Envelope" />{ typeof contact == 'object' ? contact?.email : '' }</Link></li>
                                <li><Link to={ typeof contact == 'object' ? 'tel:'+contact?.phone : '' }><Image src={Phone} alt="Envelope" />{ typeof contact == 'object' ? contact?.phone : '' }</Link></li>
                                <li className="socialFtrBox">
                                    <div className="footerSocial">
                                        <div className="singleSocial">
                                            <Link to={ typeof contact == 'object' ? contact?.fb : '' } target='_blank'>
                                                <FontAwesomeIcon icon={faFacebook} />
                                            </Link>
                                        </div>
                                        <div className="singleSocial">
                                            <Link to="#" target='_blank'>
                                                <FontAwesomeIcon icon={faInstagram} />
                                            </Link>
                                        </div>
                                        <div className="singleSocial">
                                            <Link to={ typeof contact == 'object' ? contact?.fb : '' } target='_blank'>
                                                <FontAwesomeIcon icon={faTiktok} />
                                            </Link>
                                        </div>
                                        <div className="singleSocial">
                                            <Link to="#" target='_blank'>
                                                <FontAwesomeIcon icon={faYoutube} />
                                            </Link>
                                        </div>
                                    </div>    
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>

            </Container>

            <div className="footerBottom">
                <Container>
                    <Row>
                        <Col xxl={6}>
                            <div className="footerBottomLeft">
                                <p>&copy; RELUX.GE {currentDate}. ყველა უფლება დაცულია.</p>
                            </div>
                        </Col>
                        <Col xxl={6}>
                            <div className="footerBottomRight">
                                <Link to="/terms">წესები და პირობები</Link>
                                <Link to="/privacy">კონფიდენციალურობა</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
                
            </div>

        </footer>

        
    )
    
}

export default Footer;