//import tags
import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y } from 'swiper/modules';
import { Link } from "react-router-dom";
import axios from 'axios';
import { Container, Row, Col, Image } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton'


//import resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faRightFromBracket, faUserTie, faSliders, faHeart, faLocationCrosshairs, faBed, faDoorOpen, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular, faClone } from '@fortawesome/free-regular-svg-icons';

import 'swiper/css';
import 'swiper/css/navigation';

import Propertyimage from '../../assets/image/property.jpg'

//import components
import ProfileMenu from './../../components/dynamic/ProfileMenu';

//api
// import { get_about } from '../api/Api'



function Main() {


    const [isFavourite, setIsFavourite] = useState(false);
    const [message, setMessage] = useState('');
    const [currentCurrency, setCurrentCurrency] = useState('GEL');


    const toggleFavourite = () => {
        setIsFavourite(prevState => !prevState);
        setMessage(isFavourite ? 'ფავორიტებში წარმატებით დაემატა' : 'წაიშალა ფავორიტების სიიდან');
        setTimeout(() => {
            setMessage('');
        }, 3000);
    };

    const toggleCurrency = (currency) => {
        setCurrentCurrency(currency);
    };


    return (
        <div className='main'>

            <section className="breadcumbSection" id="sectionBreadCumb">
                <Container>
                    <Row>
                        <Col xxl={12}>
                            <div className="breadcumb">
                                <div className="breadcumbLeft">
                                    <h1>ფავორიტები</h1>
                                </div>
                                <div className="breadcumbRight">
                                    <ul>
                                        <li><Link to="/">მთავარი</Link></li>
                                        <li><FontAwesomeIcon icon={faChevronRight} /></li>
                                        <li>ფავორიტები</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="contactSection">
                <Container>
                    <Row>

                        <Col xxl={4}>
                            <ProfileMenu />
                        </Col>

                        <Col xxl={8}>
                            <div className="profileCard">
                                <h4 className="profileEditBoxTitle">ჩემი ფავორიტი განცხადებები</h4>
                                <Row>

                                    <Col xxl={4}>
                                        <div className="singleProductGridBox">
                                            {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                            <div className="singleProductGridBoxImageBox">
                                                <Link to="/property/details/1">
                                                    <Swiper
                                                        modules={[Navigation, A11y]}
                                                        spaceBetween={5}
                                                        slidesPerView={1}
                                                        navigation
                                                        scrollbar={{ draggable: true }}
                                                    >
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>

                                                    </Swiper>
                                                </Link>
                                                <div className="singleProductGridTag">
                                                    <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                </div>
                                                <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                    <FontAwesomeIcon icon={isFavourite ? faHeartRegular : faHeart} />
                                                </div>
                                                <div className="singleProductGridPrice">
                                                    <h4>180000 <span >{currentCurrency}</span></h4>
                                                    <div className="priceBoxGrid">
                                                        <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                            <p>GEL</p>
                                                        </div>
                                                        <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                            <p>USD</p>
                                                        </div>
                                                        <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                            <p>EUR</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="singleProductGridBoxContant">
                                                <div className="propertyGridTags">
                                                    <div className="singleTag">ბინა</div>
                                                    <div className="singleTag">თბილისი</div>
                                                </div>
                                                <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                <div className="singlePropertyGridLocation">
                                                    <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                    <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                </div>
                                                <div className="singlePropertyListMiniBox">
                                                    <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                    <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                    <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                    <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="singleProductGridBox">
                                            {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                            <div className="singleProductGridBoxImageBox">
                                                <Link to="/property/details/1">
                                                    <Swiper
                                                        modules={[Navigation, A11y]}
                                                        spaceBetween={5}
                                                        slidesPerView={1}
                                                        navigation
                                                        scrollbar={{ draggable: true }}
                                                    >
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>

                                                    </Swiper>
                                                </Link>
                                                <div className="singleProductGridTag">
                                                    <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                </div>
                                                <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                    <FontAwesomeIcon icon={isFavourite ? faHeartRegular : faHeart} />
                                                </div>
                                                <div className="singleProductGridPrice">
                                                    <h4>180000 <span >{currentCurrency}</span></h4>
                                                    <div className="priceBoxGrid">
                                                        <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                            <p>GEL</p>
                                                        </div>
                                                        <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                            <p>USD</p>
                                                        </div>
                                                        <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                            <p>EUR</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="singleProductGridBoxContant">
                                                <div className="propertyGridTags">
                                                    <div className="singleTag">ბინა</div>
                                                    <div className="singleTag">თბილისი</div>
                                                </div>
                                                <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                <div className="singlePropertyGridLocation">
                                                    <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                    <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                </div>
                                                <div className="singlePropertyListMiniBox">
                                                    <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                    <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                    <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                    <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="singleProductGridBox">
                                            {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                            <div className="singleProductGridBoxImageBox">
                                                <Link to="/property/details/1">
                                                    <Swiper
                                                        modules={[Navigation, A11y]}
                                                        spaceBetween={5}
                                                        slidesPerView={1}
                                                        navigation
                                                        scrollbar={{ draggable: true }}
                                                    >
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>

                                                    </Swiper>
                                                </Link>
                                                <div className="singleProductGridTag">
                                                    <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                </div>
                                                <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                    <FontAwesomeIcon icon={isFavourite ? faHeartRegular : faHeart} />
                                                </div>
                                                <div className="singleProductGridPrice">
                                                    <h4>180000 <span >{currentCurrency}</span></h4>
                                                    <div className="priceBoxGrid">
                                                        <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                            <p>GEL</p>
                                                        </div>
                                                        <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                            <p>USD</p>
                                                        </div>
                                                        <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                            <p>EUR</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="singleProductGridBoxContant">
                                                <div className="propertyGridTags">
                                                    <div className="singleTag">ბინა</div>
                                                    <div className="singleTag">თბილისი</div>
                                                </div>
                                                <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                <div className="singlePropertyGridLocation">
                                                    <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                    <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                </div>
                                                <div className="singlePropertyListMiniBox">
                                                    <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                    <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                    <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                    <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="singleProductGridBox">
                                            {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                            <div className="singleProductGridBoxImageBox">
                                                <Link to="/property/details/1">
                                                    <Swiper
                                                        modules={[Navigation, A11y]}
                                                        spaceBetween={5}
                                                        slidesPerView={1}
                                                        navigation
                                                        scrollbar={{ draggable: true }}
                                                    >
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>

                                                    </Swiper>
                                                </Link>
                                                <div className="singleProductGridTag">
                                                    <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                </div>
                                                <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                    <FontAwesomeIcon icon={isFavourite ? faHeartRegular : faHeart} />
                                                </div>
                                                <div className="singleProductGridPrice">
                                                    <h4>180000 <span >{currentCurrency}</span></h4>
                                                    <div className="priceBoxGrid">
                                                        <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                            <p>GEL</p>
                                                        </div>
                                                        <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                            <p>USD</p>
                                                        </div>
                                                        <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                            <p>EUR</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="singleProductGridBoxContant">
                                                <div className="propertyGridTags">
                                                    <div className="singleTag">ბინა</div>
                                                    <div className="singleTag">თბილისი</div>
                                                </div>
                                                <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                <div className="singlePropertyGridLocation">
                                                    <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                    <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                </div>
                                                <div className="singlePropertyListMiniBox">
                                                    <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                    <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                    <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                    <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="singleProductGridBox">
                                            {message && <div className="addedToFafourites"><h4>{message}</h4></div>}
                                            <div className="singleProductGridBoxImageBox">
                                                <Link to="/property/details/1">
                                                    <Swiper
                                                        modules={[Navigation, A11y]}
                                                        spaceBetween={5}
                                                        slidesPerView={1}
                                                        navigation
                                                        scrollbar={{ draggable: true }}
                                                    >
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBoxImage">
                                                                <Image src={Propertyimage} alt="Property image" />
                                                            </div>
                                                        </SwiperSlide>

                                                    </Swiper>
                                                </Link>
                                                <div className="singleProductGridTag">
                                                    <Link to="/"><h4>ექსკლუზიური</h4></Link>
                                                </div>
                                                <div className="singleProductGridFavourite" onClick={toggleFavourite}>
                                                    <FontAwesomeIcon icon={isFavourite ? faHeartRegular : faHeart} />
                                                </div>
                                                <div className="singleProductGridPrice">
                                                    <h4>180000 <span >{currentCurrency}</span></h4>
                                                    <div className="priceBoxGrid">
                                                        <div className={`singlePrice ${currentCurrency === 'GEL' ? 'active' : ''}`} onClick={() => toggleCurrency('GEL')}>
                                                            <p>GEL</p>
                                                        </div>
                                                        <div className={`singlePrice ${currentCurrency === 'USD' ? 'active' : ''}`} onClick={() => toggleCurrency('USD')}>
                                                            <p>USD</p>
                                                        </div>
                                                        <div className={`singlePrice ${currentCurrency === 'EUR' ? 'active' : ''}`} onClick={() => toggleCurrency('EUR')}>
                                                            <p>EUR</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="singleProductGridBoxContant">
                                                <div className="propertyGridTags">
                                                    <div className="singleTag">ბინა</div>
                                                    <div className="singleTag">თბილისი</div>
                                                </div>
                                                <Link to="/property/details/1"><h1>იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს</h1></Link>
                                                <div className="singlePropertyGridLocation">
                                                    <FontAwesomeIcon icon={faLocationCrosshairs} />
                                                    <p>ა.წერეთლის გამზირი #137, 0119</p>
                                                </div>
                                                <div className="singlePropertyListMiniBox">
                                                    <div className="singlePropertyGridMini" title="საძინებელი"><FontAwesomeIcon icon={faBed} />: <span>4</span></div>
                                                    <div className="singlePropertyGridMini" title="ოთახი"><FontAwesomeIcon icon={faDoorOpen} />: <span>3</span></div>
                                                    <div className="singlePropertyGridMini" title="სართული"><FontAwesomeIcon icon={faLayerGroup} />: <span>12/14</span></div>
                                                    <div className="singlePropertyGridMini" title="ფართი"><FontAwesomeIcon icon={faClone} />: <span>120 მ<sup>2</sup></span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
        </div>
        
    )

}

export default Main;