//import tags
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

// import components
import Cityes from '../components/dynamic/Cityes'



function Cities() {

    return (

        <div className='main'>

            <section className="breadcumbSection" id="sectionBreadCumb">
                <Container>
                    <Row>
                        <Col xxl={12}>
                            <div className="breadcumb">
                                <div className="breadcumbLeft">
                                    <h1>ქალაქები</h1>
                                </div>
                                <div className="breadcumbRight">
                                    <ul>
                                        <li><Link to="/">მთავარი</Link></li>
                                        <li><FontAwesomeIcon icon={faChevronRight} /></li>
                                        <li>ქალაქები</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* <Skeleton count={1} height={20} width={150} /> */}
            <Cityes />
        </div>
    )

}

export default Cities;